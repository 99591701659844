import {createAction} from "redux-act";
import {IUser, IUserUpdate} from "modules/types";
import {ReturnFormNames} from "modules/utils/enums";

export interface IUserBackdoorLoginPayload {
	email: string;
}

export const backdoorLogin = createAction<IUserBackdoorLoginPayload>();
export const backdoorLoginSuccess = createAction<IUser>();
export const backdoorLoginFailed = createAction<Error>();

export interface IUserLoginPayload {
	code: string;
	verifier: string;
	country?: string;
}

export const userLogin = createAction<IUserLoginPayload>();
export const userLoginSuccess = createAction<IUser>();
export const userLoginFailed = createAction<Error>();
export const userAskForRegistration = createAction<IUser>();
export const userAskForReturn = createAction();

export const userFetch = createAction();
export const userFetchSuccess = createAction<IUser>();
export const userFetchFailed = createAction<Error>();

export interface IUserReturnPayload {
	[ReturnFormNames.Sponsor]: 1 | 0;
}

export const userReturn = createAction<IUserReturnPayload>();
export const userReturnSuccess = createAction<IUser>();

export interface IUserRegisterForGamePayload extends IUserReturnPayload {
	terms: 1;
}

export const userRegisterForGame = createAction<IUserReturnPayload>();
export const userRegisterForGameSuccess = createAction<IUser>();

export const userCreate = createAction<FormData>();
export const userCreateSuccess = createAction<IUser>();
export const userCreateFailed = createAction<Error>();

export const userLogout = createAction();
export const userLogoutSuccess = createAction();
export const userLogoutFailed = createAction<Error>();

export const userUpdate = createAction<IUserUpdate>();
export const userUpdateSuccess = createAction<IUser>();
export const userUpdateFailed = createAction<Error>();
