import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getUserObject, isAdditionalDataRequired, isLoggedIn, isSessionChecked} from "modules/selectors";
import {userFetch} from "modules/actions";
import Preloader from "components/Preloader";
import {isEmpty} from "lodash";
import {IS_API_ON_THE_SAME_HOST} from "modules/utils";

export const Session: React.FC = ({children}) => {
	const dispatch = useDispatch();
	const is_logged_in = useSelector(isLoggedIn);
	const is_session_checked = useSelector(isSessionChecked);
	const is_additional_data_required = useSelector(isAdditionalDataRequired);
	const user = useSelector(getUserObject);
	const is_request = isEmpty(user) && !IS_API_ON_THE_SAME_HOST;

	useEffect(() => {
		if ((!is_logged_in && !is_additional_data_required) || is_request) {
			dispatch(userFetch());
		}
	}, [dispatch, is_logged_in, is_additional_data_required, is_request]);

	useEffect(() => {
		window.dataLayer.push({
			'user_id': user.dorna_id
		});
	}, [user.dorna_id]);


	return is_session_checked ? <React.Fragment>{children}</React.Fragment> : <Preloader />;
};
