import styled from "styled-components";

export const StyledLink = styled.a`
	display: inline-block;
	border-bottom: 1px solid #fff;
	font-size: 16px;
	//padding-bottom: 6px;
	cursor: pointer;
	
	&:hover {
		border-color: transparent;
	}
`;