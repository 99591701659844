import Input from './Input';
import styled from 'styled-components';
import zoom from 'assets/img/zoom.svg';

export const SearchInput = styled(Input)`
	background-image: url("${zoom}");
	background-repeat: no-repeat;
	background-position: calc(100% - 20px) center;
	background-size: 15px;
	padding-right: 20px;
`;

export default SearchInput;
