import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {getJoinedCodes, getMotulLeagueForJoin} from "modules/selectors/leagues";
import styled from "styled-components";
import {postLeagueJoin} from "modules/actions/leagues";
import logo from "assets/img/motul-full_horizontal.svg";
import {isEmpty} from "lodash";
import {Redirect} from "react-router";
import {ButtonPrimary} from "components/FormElements";

const Wrapper = styled.section`
	width: 100%;
	padding: 16px 20px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #232930;
	margin-top: 60px;
	button {
		width: 80px;
	}
	p {
		color: #fff;
	}
`;

const LeagueData = styled.div``;
const LeagueName = styled.div`
	font-size: 20px;
	font-family: var(--fontFamilyAccent);
	line-height: 21px;
	color: #fff;
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	img {
		margin-right: 3px;
	}
`;

const LeagueFooter = styled.div`
	color: #fff;
	font-family: MotoGPText;
	font-size: 15px;
	line-height: 15px;
	font-weight: 700;
	span {
		text-transform: capitalize;
	}
`;

export const JoinMotulLeagueBanner: React.FC = () => {
	const league = useSelector(getMotulLeagueForJoin);
	const dispatch = useDispatch();
	const joined_ids = useSelector(getJoinedCodes) || [];

	if (!league || isEmpty(league)) {
		return null;
	}

	const {name, privacy, start_event_id, num_teams, code, id} = league;
	if (joined_ids[id]) {
		return <Redirect to={`/leagues/${id}/rankings`} />;
	}
	const handleJoin = () => {
		window.dataLayer.push({
			event: "League",
			eventAction: "Join",
			eventLabel: "leagues:button:join",
			eventCategory: "click",
		});

		dispatch(postLeagueJoin({code, league_id: id}));
	};

	return (
		<Wrapper>
			<LeagueData>
				<LeagueName>
					<img src={logo} alt="Motul" />
					{name}
				</LeagueName>
				<LeagueFooter>
					{num_teams} Players &nbsp;&nbsp;&nbsp;&nbsp; <span>{privacy}</span> League
					starting Game Week {start_event_id}
				</LeagueFooter>
			</LeagueData>
			<ButtonPrimary onClick={handleJoin}>Join</ButtonPrimary>
		</Wrapper>
	);
};
