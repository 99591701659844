import React from "react";
import styled, {css} from "styled-components";
import sub_header_bg from "assets/img/masthead.jpg";
// import sub_header_bg_mobile from 'assets/img/sub_header_bg_mobile.png';
import motulBG from "assets/img/league_banner.jpg";

const getBG = ({isMotul}: {isMotul: boolean}) => {
	return isMotul
		? css`
				background-image: url(${motulBG});
				background-position: top left;
		  `
		: css`
				background-image: url(${sub_header_bg});
				background-position: top left;
		  `;
};

const SubHeaderWrapper = styled.div<{isMotul: boolean}>`
	width: 100%;
	height: 151px;

	background-size: cover;

	background-repeat: no-repeat;
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;

	@media screen and (max-width: 992px) {
		height: 120px;
	}

	h1 {
		font-size: 32px;
		line-height: 32px;
		color: #ffffff;
		font-family: var(--fontFamilyAccent);
		text-transform: uppercase;
		margin-bottom: 20px;
		text-align: center;

		&.text-normal {
			text-transform: none;
		}

		@media screen and (max-width: 992px) {
			font-size: 24px;
			margin-bottom: 5px;
			${({isMotul}) => (isMotul ? "display: none;" : "")}
		}
	}

	p {
		text-align: center;
		font-size: 16px;
		line-height: 19px;
		color: #eaeaea;
		max-width: 600px;
	}

	${getBG}
`;

interface ISubHeader {
	title?: string;
	description?: string;
	titleClass?: string;
	isMotul?: boolean;
}

export const SubHeader: React.FC<ISubHeader> = ({
	title,
	description,
	titleClass,
	isMotul = false,
}) => (
	<SubHeaderWrapper isMotul={isMotul}>
		{title && <h1 className={titleClass ? titleClass : ""}>{title}</h1>}
		{description && <p>{description}</p>}
	</SubHeaderWrapper>
);

export default SubHeader;
