import * as _ from "lodash";
import React from "react";
import styled, {css} from "styled-components";
import arrow from "assets/img/accordion-arrow.svg";
import arrow_down from "assets/img/accordion-arrow-down.svg";

const isOpen = ({is_open}: IState) =>
	is_open &&
	css`
		max-height: 1000px;
		overflow: scroll;
	`;

const ContentWrapper = styled.div<IState>`
	height: auto;
	max-height: 0;
	transition: max-height 0.5s ease;
	overflow: hidden;
	color: #fff;
	line-height: 26px;
	font-size: 14px;
	box-sizing: border-box;
	font-family: "MotoGPText", sans-serif;
	${isOpen};

	h1,
	h2,
	h3,
	h4,
	h5 {
		color: #fff;
		font-size: 28px;
		font-weight: 600;
		margin-bottom: 25px;
	}

	h4,
	h5 {
		font-size: 20px;
		color: #fff;
	}
`;

const isOpenIcon = ({is_open}: ICustomIconProps) =>
	is_open &&
	css`
		transform: rotate(180deg) translateY(50%);
	`;

const getArrow = ({is_open}: ICustomIconProps) => `
	background: url("${is_open ? arrow : arrow_down}") center no-repeat;
`;

const Icon = styled.div<ICustomIconProps>`
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 24px;
	height: 24px;
	${getArrow};
	background-size: 16px;
	cursor: pointer;
	transition: transform 0.3s ease;
	right: 20px;
	${isOpenIcon}
`;

interface ICustomIcon {
	open_icon: string;
	closed_icon: string;
}

interface ICustomIconProps extends IState {
	customIcon?: ICustomIcon;
}

const CustomIcon = styled(Icon)`
	transform: none;
	top: 15px;
	background-image: url(${({is_open, customIcon}) =>
		customIcon ? (is_open ? customIcon.open_icon : customIcon.closed_icon) : ""});
`;

const ToogleIconWapper = styled.div<Partial<IIcon>>`
	position: relative;
	pointer-events: all;
	cursor: ${({scope_on_click}) => (scope_on_click ? "pointer" : "inherit")};
	min-height: 60px;
	display: flex;
	align-items: center;
	color: ${({is_open}) => (is_open ? "#E41210" : "#fff")};
	font-weight: 600;
	${({is_open, have_border}) =>
		is_open && have_border ? "border-bottom: 2px solid #CF0072;" : ""};
	font-family: "MotoGPText", sans-serif;
	text-transform: uppercase;

	@media screen and (max-width: 640px) {
		border-bottom: none;
	}
`;

interface IIcon {
	scope_on_click?: boolean;
	state: boolean;
	children: React.ReactNode;
	onClick: any;
	customIcon?: ICustomIcon;
	is_open?: boolean;
	have_border?: boolean;
}

interface IState {
	is_open: boolean;
}

interface IProps {
	children: any;
}

export class Accordion extends React.Component<IProps, IState> {
	public static Content = ({children, state}: any) => {
		return <ContentWrapper is_open={state}>{children}</ContentWrapper>;
	};

	public static ToggleIcon = (props: IIcon) => {
		const {children, onClick, state, scope_on_click, customIcon, have_border, ...rest} = props;
		const IconComponent = customIcon ? CustomIcon : Icon;
		const toogleWrapperClick = scope_on_click ? onClick : _.noop;
		const toogleIconClick = scope_on_click ? _.noop : onClick;

		return (
			<ToogleIconWapper
				onClick={toogleWrapperClick}
				scope_on_click={scope_on_click}
				is_open={state}
				have_border={have_border}>
				{children}

				<IconComponent
					onClick={toogleIconClick}
					is_open={state}
					customIcon={customIcon}
					{...rest}
				/>
			</ToogleIconWapper>
		);
	};

	public state = {
		is_open: false,
	};

	public toggleState() {
		this.setState((state: IState) => ({
			is_open: !state.is_open,
		}));
	}

	public render() {
		const {children} = this.props;
		const {is_open} = this.state;

		return children ? children(this.toggleState.bind(this), is_open) : null;
	}
}

export default Accordion;
