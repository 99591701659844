import styled from "styled-components";

export const LeagueFooterWrapper = styled.div`
	display: flex;
	align-items: flex-end;
	justify-content: flex-start;
	height: 35px;
	gap: 5px;

	p {
		font-size: 14px;
		color: #fff;
		font-weight: 700;

		&.capitalize {
			text-transform: capitalize;
		}
	}

	@media screen and (max-width: 992px) {
		max-width: 85%;

		p {
			font-size: 12px;
		}
	}
`;
