import styled from "styled-components";

export const JoinLeagueContentWrapper = styled.div`
	height: 80px;
	background-color: #232930;
	margin-bottom: 4px;
	padding: 20px;
	box-sizing: border-box;
	display: flex;
	flex-flow: column;
	align-items: flex-start;
	justify-content: center;
	position: relative;
`;
