import React from "react";
import {Redirect, Route, RouteProps} from "react-router-dom";
import {useSelector} from "react-redux";
import {isLoggedIn} from "modules/selectors";

export const PrivateRoute: React.FC<RouteProps> = (props) =>
	useSelector(isLoggedIn) ? (
		<Route {...props} />
	) : (
		<Redirect
			to={{
				pathname: "/",
				state: {from: props.location},
			}}
		/>
	);
