import styled from 'styled-components';
import {PureButton} from "components/FormElements/PureButton";

export const ButtonPrimary = styled(PureButton)`
	--hoverBackgroundColor: #A50301;
	--bgColor: #C80503;
	
    background-color: var(--bgColor);
    border-radius: 4px;
    height: 40px;
    color: #FFFFFF;
    font-family: var(--fontFamilyPrimary);
    font-size: 16px;
    width: 100%;
    cursor: pointer;
	transition: all 250ms ease-in-out;
	
	&:hover:not(:disabled) {
		background: var(--hoverBackgroundColor);
	}
    
    &:disabled {
        background: #959595;
        cursor:default;
	    color: rgba(255,255,255,.4)
    }
	&.green {
		--bgColor: #1DB72B;
		--hoverBackgroundColor: #0F981C;
	}
	
	&.flexed {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 5px;
	}
`;

export default ButtonPrimary;
