import {useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import styled from "styled-components";
import React, {useEffect} from "react";
import {isLoggedIn} from "modules/selectors/user";

export const AdvertRectangleWrapper = styled.div`
	width: 728px;
	height: 100px;
	min-height: 100px;
	margin: 58px auto 0;
	position: relative;
	overflow: hidden;

	@media screen and (max-width: 1000px) {
		display: none;
	}
`;

export const MobileAdvertWrapper = styled.div`
	width: 365px;
	height: 100px;
	max-width: 100%;
	min-height: 100px;
	margin: 36px auto 0;
	position: relative;
	overflow: hidden;

	@media screen and (min-width: 1000px) {
		display: none;
	}
`;

const Ad = () => {
	const desktopID = "moto_gp_desktop_display";
	const mobileID = "moto_gp_mobile_display";

	useEffect(() => {
		const GT = window.googletag;

		if (GT && GT.cmd) {
			GT.cmd.push(
				() => GT.display(desktopID),
				() => GT.display(mobileID)
			);
		}
	}, [desktopID, mobileID]);

	return (
		<React.Fragment>
			<AdvertRectangleWrapper id={desktopID} />
			<MobileAdvertWrapper id={mobileID} />
		</React.Fragment>
	);
};

export const AdvertRectangle = () => {
	const is_logged_in = useSelector(isLoggedIn);
	const location = useLocation();

	if (!is_logged_in && location.pathname === "/") {
		return null;
	}

	return <Ad />;
};
