import React, {Fragment, useCallback, useEffect, useState} from "react";
import styled from "styled-components";
import {NavLink, useLocation} from "react-router-dom";
import {Container} from "components/Structure";

import {ReactComponent as MainLogo} from "assets/img/mainlogo.svg";
import logo from "assets/img/logo.svg";
import hamburger from "assets/img/hamburger.svg";
import no_avatar from "assets/img/no-avatar.png";
import {useSelector} from "react-redux";
import {getUserObject, isLoggedIn, isTourActive} from "modules/selectors";
import {ACCOUNT_LINK, Constant, PREDICTOR_LINK, useMediaQuery, usePrevious} from "modules/utils";
import {get} from "lodash";

const setDefaultImage = ({currentTarget}: React.SyntheticEvent<HTMLImageElement>) =>
	(currentTarget.src = no_avatar);

interface IHeaderWrapperProps {
	is_open: boolean;
}

const Nav = styled.div`
	flex: 1;
	display: flex;
	margin-left: 35px;

	@media screen and (max-width: 960px) {
		flex-flow: column;
		justify-content: center;
		height: 100%;
		overflow: auto;
		text-align: center;
		margin-left: 0;
	}
`;

const SponsorBlock = styled.div`
	font-size: 10px;
	text-align: center;
	color: #fff;

	p {
		margin-bottom: 10px;
	}

	img {
		display: block;
		margin: 0 auto;
	}

	@media screen and (max-width: 960px) {
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translate(-50%, -50%);
	}
`;

const HeaderWrapper = styled.header<IHeaderWrapperProps>`
	background: #000;
	color: #fff;
	padding: 25px 0;
	box-sizing: border-box;
	top: 0;
	left: 0;
	z-index: 10;
	width: 100%;

	img {
		display: block;
	}

	${Container} {
		display: flex;
		align-items: center;
		position: relative;
	}

	@media screen and (max-width: 960px) {
		position: ${({is_open}) => (is_open ? "fixed" : "relative")};

		${Nav},
		${SponsorBlock} {
			display: none;
		}
	}
`;

const NavItem = styled(NavLink)`
	//margin: 0 10px;
	padding: 10px 20px;
	display: inline-block;
	color: #fff;
	font-size: 20px;
	font-weight: 400;
	border-bottom: 6px solid transparent;
	transition: all 0.3s ease;

	&:hover,
	&.active {
		border-bottom-color: #c80503;
	}
	@media screen and (max-width: 1180px) {
		margin: 0 10px;
		padding: 10px 5px;
	}

	@media screen and (max-width: 960px) {
		margin: 0;

		&.active {
			border-radius: 0;
		}
	}
`;

const NavItemExternal = styled(NavItem)``;

const RightSide = styled.div`
	margin-left: auto;
	margin-right: 0;
	display: flex;
	align-items: center;
`;

const AccountLink = styled.a`
	background: #e1e1e1;
	margin-left: 50px;
	overflow: hidden;
	border-radius: 100%;
	width: 50px;
	height: 50px;
	color: #000;
	font-size: 10px;
	display: flex;
	align-items: center;
	justify-content: center;

	img {
		width: 100%;
		height: 100%;
	}

	@media screen and (max-width: 1180px) {
		margin-left: 25px;
	}

	@media screen and (max-width: 960px) {
		margin-left: 10px;
		width: 40px;
		height: 40px;
	}
`;

const Logo = styled.img`
	height: 39px;
	@media screen and (max-width: 960px) {
		height: 44px;
	}

	@media screen and (max-width: 480px) {
		height: 30px;
	}

	@media screen and (max-width: 420px) {
		height: 23px;
	}
`;

const HamburgerMenu = styled.button`
	background: #2c2a29;
	margin-right: 23px;
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 100%;
	border: 0;
	cursor: pointer;
`;

const MobileMenu = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: #000;
	padding: 80px 0 100px;
	box-sizing: border-box;
	z-index: 9;
	color: #fff;
	display: none;

	@media screen and (max-width: 960px) {
		display: block;
	}
`;

const Logout = styled(NavLink)`
	padding: 0;
	cursor: pointer;
	font-family: "MotoGPText";
	font-size: 12px;
	line-height: 14px;
	text-transform: uppercase;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 20px auto;
	font-weight: 600;
	width: 100%;
	max-width: 240px;
	height: 40px;
	background: #fff;
	color: #cf003e;
	border: 1px solid #cf003e;
`;

const Navigation = () => {
	const is_tour_active = useSelector(isTourActive);
	const is_logged_in = useSelector(isLoggedIn);
	const is_mobile = useMediaQuery("(max-width: 960px)");
	return (
		<Nav>
			<NavItem exact={true} to="/team">
				Team
			</NavItem>
			<NavItem to="/leagues" className={is_tour_active ? "active" : ""} data-tut="last_step">
				Leagues
			</NavItem>
			<NavItem to="/stats-centre">Stats Centre</NavItem>
			<NavItem to="/prizes">Prizes</NavItem>
			<NavItem to="/help">Help</NavItem>
			<NavItemExternal as="a" href={PREDICTOR_LINK} target="_blank">
				MotoGP™ Predictor
			</NavItemExternal>
			{/*<HeaderShareIcons />*/}

			{is_mobile && is_logged_in ? <Logout to="/logout">Logout</Logout> : null}
		</Nav>
	);
};

export const Header = () => {
	const is_logged_in = useSelector(isLoggedIn);
	const {pathname} = useLocation();
	const prevPathname = usePrevious(pathname);
	const is_mobile = useMediaQuery("(max-width: 960px)");
	const [is_menu_open, setMenuOpenState] = useState(false);
	const onToggleMenu = useCallback(() => setMenuOpenState(!is_menu_open), [is_menu_open]);
	const user = useSelector(getUserObject);
	const user_id = get(user, "id");
	const avatar = get(user, "avatar");
	const avatar_version = get(user, "avatar_version");
	const user_logo = avatar
		? Constant.USER_AVATAR_URL + user_id + ".png?v=" + avatar_version
		: no_avatar;

	useEffect(() => {
		const classList = document.body.classList;
		is_menu_open ? classList.add("is-menu-open") : classList.remove("is-menu-open");
	}, [is_menu_open]);

	useEffect(() => setMenuOpenState(false), [is_mobile]);

	useEffect(() => {
		if (prevPathname && prevPathname !== pathname) {
			setMenuOpenState(false);
		}
	}, [pathname, prevPathname]);

	return (
		<React.Fragment>
			<HeaderWrapper is_open={is_menu_open}>
				<Container>
					{is_mobile && is_logged_in ? (
						<HamburgerMenu onClick={onToggleMenu}>
							<img src={hamburger} alt="Menu" />
						</HamburgerMenu>
					) : null}
					<NavLink to="/">
						<MainLogo />
					</NavLink>
					{is_logged_in ? <Navigation /> : null}
					<RightSide>
						<a href="https://www.motogp.com/">
							<Logo src={logo} alt={"Moto GP"} />
						</a>
						{is_logged_in ? (
							<Fragment>
								<AccountLink as="a" href={ACCOUNT_LINK} target="_blank">
									<img
										onError={setDefaultImage}
										src={user_logo}
										alt=""
										title="My account"
									/>
								</AccountLink>
								{is_mobile ? null : <NavItem to="/logout">Logout</NavItem>}
							</Fragment>
						) : null}
					</RightSide>
				</Container>
			</HeaderWrapper>
			{is_menu_open ? (
				<MobileMenu>
					<Navigation />
					{/*<Sponsor/>*/}
				</MobileMenu>
			) : null}
		</React.Fragment>
	);
};
