import styled from "styled-components";

export const ModalCardWrapper = styled.div`
	display: flex;
	max-width: 256px;
	margin: 0 auto 12px;
	height: 69px;
	background: #33383e;
	border-radius: 4px;
	flex: 1;
	@media screen and (max-width: 768px) {
		height: 51px;
	}
`;

export const ModalCardFigure = styled.figure`
	height: 100%;
	img {
		margin: 0;
		display: block;
		width: auto;
		height: 100%;
		max-width: 100%;
	}
	@media screen and (max-width: 550px) {
		width: 44px;
	}
`;

export const ModalCardFigureReverseModal = styled.figure`
	height: 100%;
	img {
		margin: 0;
		display: block;
		width: auto;
		height: 100%;
		max-width: 100%;
	}
	@media screen and (max-width: 550px) {
		width: 44px;
	}
`;

export const ModalCardPlayerDescriptionBlock = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	padding-left: 10px;
	box-sizing: border-box;
`;
export const ModalCardRiderInfo = styled.p`
	color: #fff;
	font-size: 10px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	b {
		font-weight: 700;
		margin-bottom: 5px;
	}

	@media screen and (max-width: 1000px) {
		font-size: 10px;
	}
`;
export const ModalCardName = styled.div`
	white-space: nowrap;
	color: #fff;
	font-size: 13px;
	b {
		font-family: var(--fontFamilyAccent);
	}

	@media screen and (max-width: 1000px) {
		font-size: 14px;
	}
`;

export const ModalCardConstructorName = styled.div`
	white-space: nowrap;
	color: #fff;
	box-sizing: border-box;
	b {
		font-weight: 600;
	}

	@media screen and (max-width: 1000px) {
		font-size: 14px;
	}
`;

export const TradeRow = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 52px;
	.icon {
		position: absolute;
		left: 50%;
		top: 50%;
		width: 52px;
		transform: translate(-50%, -50%);
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 12px;
		svg {
			margin-bottom: 12px;
			width: 32px;
		}
	}
	@media screen and (max-width: 460px) {
		gap: 32px;
		.icon {
			width: 32px;
		}
	}
`;

export * from "./ModalCardConstructor";
export * from "./ModalCardRider";
export * from "./ModalCardConstructorOrTeamReverseTrade";
export * from "./ModalCardRiderReverseTrade";
