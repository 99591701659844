import styled from "styled-components";
import {Container} from "components/Structure";
import {PureButton} from "components/FormElements";

export const Tabs = styled(Container)`
	width: 100%;
	display: flex;
	align-items: center;
	margin-bottom: 24px;
	box-sizing: border-box;
`;
export const Tab = styled(PureButton)`
	font-family: var(--fontFamilyAccent);
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	height: 52px;
	line-height: 100%;
	display: flex;
	align-items: center;
	text-transform: uppercase;
	border-bottom: 6px solid transparent;
	color: #fff;
	padding: 0 20px;
	transition: all 250ms ease-in-out;
	box-sizing: border-box;
	&:hover,
	&.selected {
		color: var(--primaryColor);
		border-bottom-color: var(--primaryColor);
	}
	@media screen and (max-width: 375px) {
		font-size: 16px;
	}
`;