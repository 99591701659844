import styled, {css} from "styled-components";
import {TooltipBubble} from "../Tooltip";
import {PureButton} from "components/FormElements";

interface ITrProps {
	highlight?: boolean;
}

export const Tr = styled.div<ITrProps>`
	display: flex;
	align-items: center;
	min-width: 1000px;
	box-sizing: border-box;
`;

interface ITdProps {
	width?: string;
	is_live?: boolean;
}

export const Td = styled.div<ITdProps>`
	width: ${({width}) => width};
	min-width: ${({width}) => width};
	height: 104px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;
	margin: 0 0.5%;
	cursor: default;
	font-weight: 700;
	font-size: 18px;
	font-family: var(--fontFamilyPrimary);
	line-height: 24px;
	color: ${({is_live}) => (is_live ? "#1DB72B" : "#fff")};
	&:first-child {
		position: sticky;
		left: 0;
		margin-left: 0;
		z-index: 2;
	}

	&:last-child {
		position: sticky;
		right: 0;
		margin-right: 0;
		z-index: 2;
	}

	position: relative;

	${TooltipBubble} {
		display: none;
		font-size: 10px;
	}

	&:hover,
	&:focus {
		${TooltipBubble} {
			display: block;
		}
	}
`;

Td.defaultProps = {
	width: "8%",
};

export const TdFirst = styled(Td)<ITdProps>`
	width: ${({width}) => width};
	min-width: 150px;
	text-align: left;
	justify-content: flex-start;
	margin-right: 0;
	position: sticky;
	left: 0;
`;

export const TdLast = styled(Td)``;

TdLast.defaultProps = {
	width: "5%",
};

TdFirst.defaultProps = {
	width: "30%",
};

export const THeadItemFirst = styled(TdFirst)`
	padding-left: 10px;
	text-align: left;
`;

export const THeadItemSecond = styled(Td)`
	text-align: left;
`;

export const Thead = styled.div`
	width: 100%;
	//overflow-y: hidden;
	//overflow-x: auto;
	padding-top: 100px;
	margin-top: -100px;

	${Td} {
		font-family: var(--fontFamilyPrimary);
		font-size: 16px;
		line-height: 16px;
		font-weight: 400;
		height: 60px;
		text-transform: capitalize;
		color: #b9babb;
	}
`;

const getTrBodyBackground = ({highlight}: ITrProps) => (highlight ? "#171C20" : "#33383E");

export const TrBody = styled(Tr)`
	background: ${getTrBodyBackground};
	margin-bottom: 2px;
	border-radius: 4px;
	box-sizing: border-box;

	@media screen and (max-width: 1000px) {
		${TdFirst} {
			background: ${getTrBodyBackground};
		}

		${TdLast} {
			background: ${getTrBodyBackground};
			box-shadow: -1px 0px 4px rgba(0, 0, 0, 0.25);
		}
	}
`;

export const TBody = styled.div`
	width: 100%;
	//overflow: auto hidden;
`;

interface IButtonAddStyled {
	is_grayed?: boolean;
}

export const ButtonAdd = styled(PureButton)<IButtonAddStyled>`
	width: 40px;
	height: 40px;

	&:disabled {
		opacity: 0.5;
		cursor: default;
	}

	${(props) =>
		props.is_grayed &&
		css`
			opacity: 0.5;
			&:hover {
				background: #575e66;
				cursor: default;
			}
		`}
`;

export const EventFlag = styled.img`
	width: 40px;
	height: 27px;
	margin-left: 10px;
`;

export interface IRacePositionProps {
	highlight: boolean;
}

export const RacePosition = styled.div<IRacePositionProps>`
	background: ${({highlight}) => (highlight ? "#C80502" : "#fff")};
	width: 24px;
	height: 24px;
	min-width: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 4px;
	color: ${({highlight}) => (highlight ? "#fff" : "#000")};
	font-size: 16px;
	font-weight: 700;

	&:last-child {
		margin-right: 0;
	}
`;

export const HeaderArrowButton = styled.button`
	background: none;
	border: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	width: 100%;
	height: 30px;
	outline: 0;

	&:disabled {
		opacity: 0;
		cursor: default;
	}
`;

export const TdInner = styled.div`
	position: relative;
	z-index: 1;
	display: flex;
	align-items: center;
	width: 100%;
	height: 100%;
`;

export const TheadButton = styled(HeaderArrowButton)`
	padding: 0;
	width: 20px;
`;

export const TheadButtonTop = styled(TheadButton)`
	left: 0;
`;

export const TheadButtonBottom = styled(TheadButton)`
	right: 0;
`;
