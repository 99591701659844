import {
	get,
	identity,
	memoize,
	sumBy,
	size
} from "lodash";
import { createSelector } from "reselect";
import { IStore} from "../../types";
import { getAllConstructorsById } from "../constructors";
import { getAllRidersById } from "../riders";
import {
	getMyTeam,
	getTeamSquadsID,
	getTeamConstructorsID,
	getTeamRidersID,
	isTradeInProgress,
} from "../team";
import {getSquadsById} from "../squads";

import {Constant} from 'modules/utils/Constant';


export const getTeamValue = createSelector(
	getTeamRidersID,
	getAllRidersById,
	getTeamConstructorsID,
	getAllConstructorsById,
	getTeamSquadsID,
	getSquadsById,
	(rider_ids, riders_by_id, constructor_ids, constructors_by_id, squad_id, squads_by_id) =>
		memoize((event_id: number) => {
			const riders_value = sumBy(rider_ids, (id: number) => {
				return get(riders_by_id[id], `stats.prices[${event_id}]`, 0)
			});

			const constructors_value = sumBy(constructor_ids, (id: number) => {
				return get(constructors_by_id[id], `stats.prices[${event_id}]`, 0)
			});

			const squads_value = sumBy(squad_id, (id: number) => {
				return get(squads_by_id[id], `stats.prices[${event_id}]`, 0)
			});

			return riders_value + constructors_value + squads_value;
		})
);

export const getRemainingSalary = createSelector(
	getMyTeam,
	(state: IStore) => state,
	({ salary_cap }, state) => (event_id: number) => {
		const team_value = getTeamValue(state)(event_id);

		return  salary_cap - team_value
	}
);

export const getAllTeamID = createSelector(
	getTeamConstructorsID,
	getTeamRidersID,
	getTeamSquadsID,
	(constructors, riders, squads) => [...constructors, ...riders, ...squads]
);

export const hasSomeoneInTeam = createSelector(
	getAllTeamID,
	ids => ids.some(identity)
);

export const isTeamFullFilled = createSelector(
	getAllTeamID,
	ids => ids.every(identity)
);

export const getRemainingTurbo = createSelector(
	getMyTeam,
	({  turbo_rider_free }) => turbo_rider_free
);

export const isNoRemainingTurbo = createSelector(
	getMyTeam,
	(team) => {
		const {turbo_rider_events} = team;
		const used_turbo = size(turbo_rider_events);

		return Constant.AMOUNT_OF_TURBO === used_turbo;
	}
);


export const isOneTrade = createSelector(
	isTradeInProgress,
	isTeamFullFilled,
	(is_in_progress, is_team_full) => is_in_progress && is_team_full
);