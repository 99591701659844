import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
	background-color: rgba(0, 0, 0, 0.4);
	min-height: 40px;
	display: flex;
	align-items: center;
	margin: 0 auto;
	width: 100%;
	justify-content: center;
	color: #fff;
	font-size: 16px;
	letter-spacing: 0;
	line-height: 19px;
	font-family: "MotoGPText";
	div {
		display: flex;
		align-items: center;
		
		img {
			margin-right: 10px;
		}
	}
	@media screen and (max-width: 992px){
		div {
			display: block;
			&:first-child {
				margin-right: 10px;
			}
		}
		img {
			margin-right: 0;
		}
		padding: 10px 20px;
		box-sizing: border-box;
	}
`;

export const SponsorInfo: React.FC = () => {

	return (
		<Wrapper>
			<div>
				MotoGP™ Fantasy boost gives you a competitive edge by doubling one of your riders’ points in 3 races this season
			</div>
		</Wrapper>
	)
};