import { IStore } from "modules/types";
import { createSelector } from "reselect";
import { hasSomeoneInTeam } from "modules/selectors/team-calculations";

export const getFaq = ({ help }: IStore) => help.faq;

export const getHelpPages = ({ help }: IStore) => help.help_pages;

const isInfoVisible = ({ help }: IStore) => help.info;

export const isInfoBlockVisible = createSelector(
	isInfoVisible,
	hasSomeoneInTeam,
	(is_info_visible, has_someone_in_team) => is_info_visible && !has_someone_in_team
);