import {Constant, RIDER_IMAGE_VERSION} from 'modules/utils/Constant';
import { onImageErrorHideIt } from 'modules/utils';
import React from 'react';
import { useSelector } from 'react-redux';
import { getAllRidersById } from 'modules/selectors/riders';
import { getAllConstructorsById } from 'modules/selectors/constructors';
import {
	ModalCardFigureReverseModal,
	ModalCardName,
	ModalCardPlayerDescriptionBlock,
	ModalCardRiderInfo,
	ModalCardWrapper
} from 'components';


interface IModalCard {
	unit_id: number
}
export const ModalCardRiderReverseTrade: React.FC<IModalCard> = ({unit_id}) => {

	const riders_by_id = useSelector(getAllRidersById);
	const constructors_by_id = useSelector(getAllConstructorsById);
	const unit =riders_by_id[unit_id];
	const unit_name = unit.first_name + ' ' + unit.last_name;
	const unit_constructor = constructors_by_id[unit.constructor_id];

	return (
		<ModalCardWrapper className="column">
			<ModalCardFigureReverseModal>
				<img
					src={`${Constant.RIDERS_URL + unit.id}_3.png?v=${RIDER_IMAGE_VERSION}`}
					onError={onImageErrorHideIt}
					alt={unit_name}
					title={unit_name}
				/>
			</ModalCardFigureReverseModal>

			<ModalCardPlayerDescriptionBlock className="revert-modal">

				<ModalCardName className="revert-modal">
					{unit.first_name}
					<br/>
					<b>{unit.last_name}</b>
				</ModalCardName>

				{unit.squad ? (
					<ModalCardRiderInfo>
						{unit.squad.name}
					</ModalCardRiderInfo>
				) : null}

				{unit_constructor ? (
					<ModalCardRiderInfo>Bike: <b>{unit_constructor.name}</b></ModalCardRiderInfo>
				) : null}
			</ModalCardPlayerDescriptionBlock>

		</ModalCardWrapper>
	)
};
