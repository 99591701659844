import styled from 'styled-components';

export const CreateLeagueWrapper = styled.div`
    margin: 80px 0;
    display: flex;
    justify-content: space-between;
    
    @media screen and (max-width: 992px){
    	flex-flow: column;
    	margin: 0;
    }
`;
