export enum ReturnFormNames {
	Sponsor = "sponsor",
	Terms = "terms",
}

export enum ShareNet {
	Facebook = "fb",
	Twitter = "tw",
	WhatsApp = "wa",
}

export enum ShareSource {
	General = "general",
	League = "league",
}

export enum CrossPromotionModalType {
	Idle,
	Show,
	Hidden,
}

export enum RequestStateType {
	Loading,
	Success,
	Error,
	Idle,
}

export enum PoolType {
	Idle = "",
	Riders = "rider",
	Constructors = "constructor",
	Squads = "squad",
}

export enum SortColumnType {
	Price,
	Status,
	ActualPoints,
	Podiums,
}

export enum SortColumnOrder {
	ASC = "asc",
	DESC = "desc",
}

export enum RiderStatusType {
	Active = "active",
	OnFire = "on_fire",
	Injured = "injured",
	Disqualified = "disqualified",
}
