import { Constant } from "modules/utils/Constant";

/**
 * Wrapper for localStorage that made storage key uniq for any base path.
 * It's required for multiple projects on the same domain but at different folders
 */
export abstract class Storage {
	/**
	 * Store key/value data in localStorage
	 */
	public static SET(key: string, value: any): void {
		const val = typeof value === 'string' ? value : JSON.stringify(value || '');
		localStorage.setItem(Storage.generate_key(key), val);
	}

	/**
	 * Get value by key from localStorage
	 */
	public static GET(key: string) {
		return localStorage.getItem(Storage.generate_key(key));
	}

	/**
	 * Remove value by key from localStorage
	 */
	public static REMOVE(key: string): void {
		localStorage.removeItem(Storage.generate_key(key));
	}

	/**
	 * Remove all values from localStorage
	 */
	public static FLUSH(): void {
		localStorage.clear();
	}

	private static generate_key(key: string): string {
		return Constant.BASE_URL + key + '-' + Constant.STORAGE_VERSION;
	}
}

export default Storage;