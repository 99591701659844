import { IStore } from 'modules/types';
import { createSelector } from 'reselect';
import { CrossPromotionModalType } from 'modules/utils';

export const getCrossPromotionState = (state: IStore) => state.modals.crossPromotion;
export const isNeedToShowCrossPromotion = createSelector(
	getCrossPromotionState,
	state => state === CrossPromotionModalType.Show
);
export const isCrossPromotionShown = createSelector(
	getCrossPromotionState,
	state => state === CrossPromotionModalType.Hidden
);