import React, { useCallback } from 'react';
import { useSelector } from "react-redux";
import { getGlobalError } from "modules/selectors";
import styled from 'styled-components';
import { IconClose } from "components/Icons/Close";
import { useDispatch } from "react-redux";
import { clearGlobalError } from "modules/actions";
import { ModalOverlay } from 'components/Modal/ModalOverlay';
import { ModalInner, CloseModalButton } from 'components/Modal';

const Text = styled.div`
	text-align: center;
`;

const doNothing = (event: React.SyntheticEvent<HTMLDivElement>) => {
	event.preventDefault();
	event.stopPropagation();
};

export const ModalError = () => {
	const error = useSelector(getGlobalError);
	const dispatch = useDispatch();

	const closeModal = useCallback(() => {
		dispatch(clearGlobalError())
	}, [dispatch]);

	if(!error) {
		return null;
	}

	return (
		<ModalOverlay onClick={closeModal}>
			<ModalInner onClick={doNothing}>
				<CloseModalButton onClick={closeModal}>
					<IconClose/>
				</CloseModalButton>
				<Text>{error}</Text>
			</ModalInner>
		</ModalOverlay>
	);
};