import styled from "styled-components";
import {NavLink} from "react-router-dom";
import chevron_right from "assets/img/chevron_right.svg";

export const LeagueContentWrapper = styled.div`
	background-color: #575e66;
	height: 99px;
	padding: 15px 20px;
	box-sizing: border-box;
	position: relative;
`;

export const Navigate = styled(NavLink)`
	width: 30px;
	height: 30px;
	background-image: url("${chevron_right}");
	background-repeat: no-repeat;
	background-size: 40%;
	background-position: center;
	position: absolute;
	display: block;
	right: 15px;
	top: 50%;
	transform: translateY(-50%);
`;
