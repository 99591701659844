import { createSelector } from "reselect";
import { IConstructor, IStore } from "modules/types";
import { getTeamConstructorsID } from "modules/selectors/team";
import { keyBy, sortBy } from 'lodash';

export const getAllConstructors = ({ constructors }: IStore) => constructors;

export const getAllConstructorsById = createSelector(
	getAllConstructors,
	constructors => keyBy<IConstructor>(constructors, 'id')
);
export const getAllConstructorsSortedByCost  = createSelector(
	getAllConstructors,
	(constructors) => sortBy(constructors, ({ cost }) => -cost)
);
export const getConstructorsWithMarkedInTeam = createSelector(
	getAllConstructorsSortedByCost,
	getTeamConstructorsID,
	(constructors, constructors_in_team) => (
		constructors.map(constr => ({
			...constr,
			is_in_team: constructors_in_team.includes(constr.id)
		}))
	)
);