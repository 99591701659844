import React, {Fragment} from "react";
import styled from "styled-components";
import LeagueButtonLeave from "./LeagueButtonLeave";
import {Privacy} from "modules/types";

const Name = styled.p`
	color: #fff;
	font-family: var(--fontFamilyAccent);
	font-size: 26px;
	line-height: 30px;
	width: 100%;
	text-align: left;
	margin: 30px 0 20px;
	text-transform: uppercase;

	@media screen and (max-width: 992px) {
		font-size: 22px;
	}
`;

const Description = styled.p`
	font-size: 16px;
	line-height: 22px;
	color: #fff;
	text-align: left;
	width: 100%;
	margin-bottom: 10px;
`;

const LeagueInfoWrapper = styled.div`
	display: flex;
	flex-flow: column;
	align-items: center;
`;

const LeagueInfoLabel = styled(Description)`
	font-weight: 700;
	margin-bottom: 0;
`;

const LeagueInfoValue = styled(Description)`
	color: #fff;
	margin-bottom: 20px;
	&:first-letter {
		text-transform: uppercase;
	}
`;

interface ILeagueInfo {
	label: string;
	value: string | number;
}

const LeagueInfo: React.FC<ILeagueInfo> = ({label, value}) => (
	<LeagueInfoWrapper>
		<LeagueInfoLabel>{label}</LeagueInfoLabel>

		<LeagueInfoValue>{value}</LeagueInfoValue>
	</LeagueInfoWrapper>
);

interface IProps {
	name: string;
	description: string;
	start_event: string;
	end_event: string;
	privacy: Privacy;
	onLeagueLeave?: () => void;
	is_commissioner?: boolean;
}

export const LeagueStaticForm: React.FC<IProps> = ({
	name,
	description,
	start_event,
	privacy,
	onLeagueLeave,
	end_event,
	is_commissioner,
}) => (
	<Fragment>
		<Name>{name}</Name>

		{description && <Description>{description}</Description>}

		<LeagueInfo label="Starting Event" value={start_event} />
		<LeagueInfo label="End Event" value={end_event} />
		<LeagueInfo label="Privacy" value={`${privacy} League`} />

		{!is_commissioner && (
			<LeagueButtonLeave onClick={onLeagueLeave}>Leave this league</LeagueButtonLeave>
		)}
	</Fragment>
);

export default LeagueStaticForm;
