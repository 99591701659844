import {ILeague, IStore} from "modules/types";
import {get} from "lodash";
import {createSelector} from "reselect";
import {RequestStateType} from "modules/utils";

export const getLeaguesStore = (state: IStore) => state.leagues;
export const getLeaguesCreateStore = (state: IStore) => state.create_league;

export const getLeagues = createSelector(
	[getLeaguesStore],
	(store: IStore["leagues"]) => store.leagues
);

export const isLeagueCreated = createSelector(
	[getLeaguesCreateStore],
	(store: IStore["create_league"]) => store.created
);

export const isLeaguesDataFetched = (state: IStore) =>
	get(state, "leagues.is_leagues_data_fetched");
export const isLeagueUpdated = (state: IStore) => get(state, "update_league.updated");
export const getCreatedLeague = (state: IStore) => get(state, "create_league.league");
export const isInvitesSended = (state: IStore) => get(state, "invite_league.is_success");
export const getFetchedLeague = (state: IStore) => state.manage_league.league as ILeague;
export const isLeagueLeaveSuccess = (state: IStore) => get(state, "leave_league.leaving");
export const getLeaguesForJoin = (state: IStore) => get(state, "join_leagues.leagues");
export const isJoinMoreLeaguesEnabled = (state: IStore) => get(state, "join_leagues.next");
export const isJoinLeaguesFetched = (state: IStore) => get(state, "join_leagues.fetched");
export const getJoinedCodes = (state: IStore) => get(state, "join_league.joined_codes");
export const getLeagueFormFilters = (state: IStore) => get(state, "join_leagues.filters");
export const getLeagueJoinedByCodeId = (state: IStore) => get(state, "join_league.joined_id");
export const getLeagueLadder = ({ladder}: IStore) => get(ladder, "ladder");
export const getLoadMoreStateForLadder = ({ladder}: IStore) => get(ladder, "next");
export const getMotulLeagueForJoin = ({join_leagues}: IStore) =>
	get(join_leagues, "motul_league_for_join");

export const isLeagueCreating = createSelector(
	getLeaguesCreateStore,
	(league) => league.league_create_state === RequestStateType.Loading
);
