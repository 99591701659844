import React from "react";
import pkceChallenge from "pkce-challenge";
import Storage from "./Storage";
import { Constant } from "./Constant";

export * from './Api';
export * from './hooks';
export * from './User';
export * from './Storage';
export * from './Constant';
export * from './currency';
export * from './dom_helpers';
export * from './social_share';
export * from './enums';

export const onImageErrorHideIt = ({currentTarget}: React.SyntheticEvent<HTMLImageElement>) => (
	currentTarget.style.display = 'none'
);

const {code_verifier, code_challenge} = pkceChallenge(128);
const client_id = "4ecd7abe-bec1-43ca-a5ec-4a4067eb703f";

export const setCodes = () => {
	const saved_code_verifier = Storage.GET("code_verifier");
	if (window.location.search.indexOf("?code=") !== -1 && saved_code_verifier) {
		Storage.SET("saved_code_verifier", saved_code_verifier);
	} else {
		Storage.REMOVE("saved_code_verifier");
	}
	Storage.SET("code_verifier", code_verifier);
	Storage.SET("code_challenge", code_challenge);
}

export const getLoginLink = () => {
	return `${
		Constant.SSO_URL
	}login/authorize?code_challenge=${code_challenge}&code_challenge_method=S256&language=en&client_id=${client_id}&redirect_uri=${Constant.REDIRECT_URL}&response_type=code&_ga=`;
};

export const getRegisterLink = () => {
	return `${Constant.SSO_URL}register?code_challenge=${code_challenge}&code_challenge_method=S256&language=en&client_id=${client_id}&redirect_uri=${Constant.REDIRECT_URL}&response_type=code&_ga=`;
};	

export const getLogoutLink = () => {
	return `${Constant.SSO_URL}en/logout?redirect_uri=${Constant.REDIRECT_LOGOUT_URL}`
};
