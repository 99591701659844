import {createAction} from "redux-act";
import {
	IEmailInvites,
	ILeague,
	ILeagueCreate,
	IFetchLeague,
	IFetchLeagueReducer,
	IPostLeagueLeave,
	ILeagueUpdateReducerState,
	ILeagueUpdate,
	IPostFetchLeaguesForJoin,
	IJoinLeaguesReducer,
	IJoinedCode,
	IPostLeagueJoin,
} from "modules/types";
import {ILadderReducer} from "modules/reducers/leagues/ladder";

export const fetchMyLeagues = createAction();
export const fetchMyLeaguesSuccess = createAction<ILeague[]>();
export const fetchMyLeaguesFailed = createAction<Error>();

export const fetchLeague = createAction<IFetchLeague>();
export const fetchLeagueSuccess = createAction<IFetchLeagueReducer>();
export const fetchLeagueFailed = createAction<Error>();

export const postLeagueCreate = createAction<ILeagueCreate>();

export const postLeagueCreateSuccess = createAction<ILeague>();
export const postLeagueCreateFailed = createAction<Error>();

export const postLeagueUpdate = createAction<ILeagueUpdate>();
export const postLeagueUpdateSuccess = createAction<ILeagueUpdateReducerState>();
export const postLeagueUpdateFailed = createAction<Error>();

export const inviteViaEmail = createAction<IEmailInvites>();
export const inviteViaEmailSuccess = createAction();
export const inviteViaEmailFailed = createAction<Error>();
export const inviteViaEmailClear = createAction();

export const postLeagueLeave = createAction<IPostLeagueLeave>();
export const postLeagueLeaveSuccess = createAction<IJoinedCode>();
export const postLeagueLeaveFailed = createAction<Error>();
export const clearLeagueLeave = createAction();

export const postLeagueJoin = createAction<IPostLeagueJoin>();
export const postLeagueJoinSuccess = createAction<IJoinedCode>();
export const postLeagueJoinFailed = createAction<Error>();

export const searchAndJoin = createAction<Partial<IPostLeagueJoin>>();
export const searchAndJoinSuccess = createAction<number>();
export const searchAndJoinFailed = createAction<Error>();

export const fetchLeaguesForJoin = createAction<IPostFetchLeaguesForJoin>();
export const fetchLeaguesForJoinSuccess = createAction<IJoinLeaguesReducer>();
export const fetchLeaguesForJoinFailed = createAction<Error>();
export const leagueCreateClear = createAction();

export interface ILeagueLadderPayload {
	id: number;
	order?: string;
	order_dir?: string;
	offset?: number;
	limit?: number;
	q?: string;
	event_id?: string;
}

export const fetchLeagueLadder = createAction<ILeagueLadderPayload>();
export const fetchLeagueLadderSuccess = createAction<ILadderReducer>();
export const fetchLeagueLadderFailed = createAction<Error>();
export const sortLeagueLadder = createAction<ILeagueLadderPayload>();
export const clearLadder = createAction();
