import * as React from "react";
import {delay} from "lodash";
import styled from "styled-components";
import {copyToClipboard} from "modules/utils";
import {ButtonSecondary} from "components/FormElements/ButtonSecodary";

const CopyButtonStyled = styled(ButtonSecondary)`
	max-width: 220px;
	font-weight: 600;
`;

interface IProps {
	value: string;
}

export const CopyButton = ({value}: IProps) => {
	const [copied, changeState] = React.useState(false);
	const delayCopy = () => {
		const TIMEOUT = 5000;
		changeState(true);
		delay(() => {
			changeState(false);
		}, TIMEOUT);
	};

	const handleClick = (event: React.SyntheticEvent<HTMLButtonElement>) => {
		event.preventDefault();

		copyToClipboard(value).then(delayCopy);
	};

	return (
		<CopyButtonStyled onClick={handleClick}>{copied ? "COPIED" : "COPY CODE"}</CopyButtonStyled>
	);
};

export default CopyButton;
