import { Preloader } from "components/Preloader";
import { userLogin } from "modules/actions";
import {
	isDataRequested,
} from "modules/selectors";
import { setCodes, Storage } from "modules/utils";
import qs from "qs";
import React, {
	useEffect,
	useState
} from "react";
import {
	useDispatch,
	useSelector
} from "react-redux";
import { useHistory, useLocation } from "react-router-dom";


interface IProps {
	children: any;
}

export const HOCSSO: React.FC<IProps> = ({children}) => {
	const dispatch = useDispatch();
	const [show_child, setShowChild] = useState(false);
	const is_data_requested = useSelector(isDataRequested);
	const {search} = useLocation();
	const history = useHistory();
	const params = qs.parse(search.substring(1));

	useEffect(() => {
		setCodes();
	}, []);


	useEffect(() => {
		const saved_code_verifier = Storage.GET("saved_code_verifier");
		if (params?.code && saved_code_verifier && !is_data_requested) {
			dispatch(
				userLogin({
					code: params.code as string,
					verifier: saved_code_verifier,
				})
			);

			history.replace({
				search: "",
			});
		}
	}, [dispatch, params, is_data_requested, history]);

	useEffect(() => (
		setShowChild(!is_data_requested)
	), [is_data_requested]);

	return show_child ? children : <Preloader/>;
};

export default HOCSSO;
