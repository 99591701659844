import React, {Fragment} from "react";
import styled from "styled-components";
import {
	ButtonPrimary,
	FormGroup,
	LeagueButtonLeave,
	LeagueFormDescription,
	LeagueStaticForm,
	RadioSwitcher,
} from "components";
import {IEvent, ILeagueCreate} from "modules/types";
import {eq, get, map, find, last} from "lodash";
import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	TextField,
} from "@mui/material";
import {useSelector} from "react-redux";
import {isLeagueCreating, getEvents} from "modules/selectors";

const EditButton = styled.span`
	color: #979797;
	letter-spacing: -0.1px;
	line-height: 22px;
	font-size: 14px;
	text-decoration: underline;
	font-weight: 500;
	cursor: pointer;
	position: absolute;
	right: 20px;
	top: 20px;

	@media screen and (max-width: 992px) {
		top: 50px;
		right: 0;
	}
`;

const CreateLeagueFormWrapper = styled.form`
	width: 100%;
	.row {
		margin-bottom: 24px;
	}
`;

interface ICreateLeagueForm {
	events: IEvent[];
	end_events: IEvent[];
	onSubmit?: (e: React.SyntheticEvent<HTMLFormElement>) => void;
	onChange?: (e: React.SyntheticEvent<HTMLFormElement> | SelectChangeEvent) => void;
	onInvalid?: (event: any) => void;
	league_form_data?: ILeagueCreate;
	button_text?: string;
	onLeagueLeave?: () => void;
	is_commissioner?: boolean;
	description?: string;
	readonly_form?: boolean;
	changeView?: () => void;
	is_form_visible?: boolean;
}

const LeagueCreate: React.FC<ICreateLeagueForm> = (props) => {
	const {
		events,
		onInvalid,
		onSubmit,
		onChange,
		league_form_data,
		button_text,
		onLeagueLeave,
		is_commissioner,
		description,
		end_events,
	} = props;
	const end_event_id = get(league_form_data, "end_event_id", last(events)?.id || 1);
	const is_started = get(league_form_data, "status", "scheduled") !== "scheduled";
	const is_disabled = is_started && !is_commissioner;
	const all_events = useSelector(getEvents);
	const is_create_disabled = useSelector(isLeagueCreating);
	console.log(events);

	return (
		<CreateLeagueFormWrapper onSubmit={onSubmit} onChange={onChange}>
			{description && <LeagueFormDescription>{description}</LeagueFormDescription>}

			<FormControl fullWidth className="row">
				<TextField
					label="Give your league a name"
					name="name"
					value={get(league_form_data, "name", "")}
					onInvalid={onInvalid}
					required={true}
					type="text"
				/>
			</FormControl>

			<FormControl fullWidth className="row">
				<InputLabel>When do you want your league to start?</InputLabel>
				<Select
					label="When do you want your league to start?"
					disabled={is_started}
					onChange={onChange}
					name="start_event_id"
					required={true}
					value={get(league_form_data, "start_event_id", 1).toString()}>
					{map(is_started ? all_events : events, (event: IEvent) => (
						<MenuItem key={event.id} value={event.id}>
							{event.name}
						</MenuItem>
					))}
				</Select>
			</FormControl>

			<FormControl fullWidth className="row">
				<InputLabel>When do you want your league to end?</InputLabel>
				<Select
					disabled={is_disabled}
					label="When do you want your league to end?"
					name="end_event_id"
					required={true}
					onChange={onChange}
					value={end_event_id.toString()}>
					{map(end_events, (event: IEvent) => (
						<MenuItem key={event.id} value={event.id}>
							{event.name}
						</MenuItem>
					))}
				</Select>
			</FormControl>
			<FormControl fullWidth className="row">
				<FormGroup title="Privacy settings">
					<RadioSwitcher
						labelOne="Public"
						labelTwo="Private"
						name="privacy"
						defaultChecked={get(league_form_data, "privacy", "public")}
						required={true}
					/>
				</FormGroup>
			</FormControl>

			<FormControl fullWidth className="row">
				<TextField
					multiline={true}
					label="Description of your League"
					value={get(league_form_data, "description", "")}
					rows={5}
					name="description"
				/>
			</FormControl>

			<ButtonPrimary type="submit" disabled={is_create_disabled}>
				{button_text ? button_text : "Create a League"}
			</ButtonPrimary>

			{eq(is_commissioner, false) && (
				<LeagueButtonLeave onClick={onLeagueLeave}>Leave this League</LeagueButtonLeave>
			)}
		</CreateLeagueFormWrapper>
	);
};

export const LeagueFormCreate: React.FC<ICreateLeagueForm> = (props) => {
	const {events, league_form_data, readonly_form, onLeagueLeave, is_commissioner, changeView} =
		props;
	const all_events = useSelector(getEvents);
	const start_event_id = get(league_form_data, "start_event_id", 1);
	const end_event_id = get(league_form_data, "end_event_id", last(events)?.id);
	const start_event = find(all_events, {id: start_event_id});
	const end_event = find(events, {id: end_event_id});

	return (
		<Fragment>
			{is_commissioner && (
				<EditButton onClick={changeView}>{readonly_form ? "Edit" : "Cancel"}</EditButton>
			)}

			{readonly_form ? (
				<LeagueStaticForm
					name={get(league_form_data, "name", "")}
					description={get(league_form_data, "description", "")}
					start_event={get(start_event, "name", "")}
					end_event={get(end_event, "name", "")}
					privacy={get(league_form_data, "privacy", "public")}
					onLeagueLeave={onLeagueLeave}
					is_commissioner={is_commissioner}
				/>
			) : (
				<LeagueCreate {...props} />
			)}
		</Fragment>
	);
};
