import React from 'react';

interface IProps {
	color?: string;
	width?: number;
	height?: number;
}

export const IconAddRider: React.FC<IProps> = ({ color, width, height }) => (
	<svg width={`${width}px`} height={`${height}px`} viewBox="0 0 18 17">
		<title>Add a rider</title>
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g transform="translate(-3.000000, 0.000000)" fill={color}>
				<path
					d="M19.6090155,8.8914829 C21.4635948,10.7462757 21.4635948,13.7535125 19.6094154,15.6089054 C17.7544361,17.4636982 14.7476695,17.4636982 12.8930901,15.6089054 C11.0387107,13.7539126 11.0387107,10.7462757 12.8930901,8.8914829 C14.7476695,7.03669012 17.7542361,7.03669012 19.6090155,8.8914829 Z M12.2910968,7.71583394 C12.4782947,7.82345673 12.6574927,7.94288202 12.8320908,8.06830858 C11.6213042,9.0605187 10.8471128,10.5664376 10.8471128,12.2501942 C10.8471128,13.6938999 11.4093066,15.0513874 12.4298952,16.0722035 C12.4974945,16.1396178 12.5666937,16.2050317 12.6374929,16.2688452 L12.3688191,16.2877089 C9.40573442,16.4816426 4.33416518,16.371891 3.95198942,15.9069685 C3.39179565,15.2606317 3.04779947,14.1301923 3,13.1215787 C3,10.8040879 4.26698592,8.79246193 6.1409651,7.71583394 C6.94895612,8.4541903 8.01534427,8.89128286 9.21653093,8.89128286 C10.4171176,8.89128286 11.4833057,8.4541903 12.2910968,7.71583394 Z M16.716,12.7635029 L16.716,14.5203505 C16.716,14.8036726 16.4863221,15.0333505 16.203,15.0333505 C15.9196779,15.0333505 15.69,14.8036726 15.69,14.5203505 L15.69,12.7635029 L13.9640782,12.7635029 L13.93167,12.7624928 C13.6637294,12.7457429 13.4510839,12.5227519 13.4510839,12.2501942 C13.4510839,11.9671342 13.6810813,11.7372855 13.9640782,11.7372855 L15.69,11.7372855 L15.69,10.1151401 C15.69,9.83181799 15.9196779,9.60214006 16.203,9.60214006 C16.4863221,9.60214006 16.716,9.83181799 16.716,10.1151401 L16.716,11.7372855 L18.5378274,11.7372855 L18.5702356,11.7382955 C18.8381761,11.7550449 19.0508219,11.9780211 19.0508219,12.2501942 C19.0510217,12.5334541 18.8208242,12.7635029 18.5378274,12.7635029 L16.716,12.7635029 Z M9.21653093,0 C11.3993067,0 12.8830902,1.7703749 12.8830902,3.95403733 C12.8830902,6.13769975 11.3993067,7.90847474 9.21653093,7.90847474 C7.03335518,7.90847474 5.54957167,6.13769975 5.54957167,3.95403733 C5.54957167,1.7703749 7.03335518,0 9.21653093,0 Z"
				/>
			</g>
		</g>
	</svg>
);

IconAddRider.defaultProps = {
	color: '#000000',
	width: 18,
	height: 17,
};