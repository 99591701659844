import React from "react";
import styled from "styled-components";
import {ILeague} from "modules/types";
import {LeagueCreate, LeagueInvite} from "components";

const LeagueAboutWrapper = styled.div`
	padding: 35px 0 50px;

	@media screen and (max-width: 992px) {
		padding: 0 20px;
	}
`;

const LeagueAboutInner = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: center;
	max-width: 880px;
	margin: 0 auto;

	> div {
		flex: 1;
		box-shadow: none;
	}

	@media screen and (max-width: 992px) {
		flex-flow: column;
	}
`;

interface ILeagueAbout {
	league: ILeague;
}

export const LeagueAbout: React.FC<ILeagueAbout> = ({league}) => {
	return (
		<LeagueAboutWrapper>
			<LeagueAboutInner>
				<LeagueInvite league={league} update={true} />
				<LeagueCreate league={league} />
			</LeagueAboutInner>
		</LeagueAboutWrapper>
	);
};

export default LeagueAbout;
