import React from 'react';
import styled from 'styled-components';
import { WhatsappShareButton } from 'react-share';
import { share, SHARE_MESSAGE_GLOBAL, ShareNet, ShareSource, shareWhatsApp } from 'modules/utils';
import { partial } from 'lodash';
import facebook from 'assets/img/facebook.svg';
import twitter from 'assets/img/twitter.svg';
import whatsapp_icon from 'assets/img/Whatsapp.svg';

const ShareIcons = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	button {
		cursor: pointer;
		background: none;
		outline: none;
		border: 1px solid #fff;
		border-radius: 50%;
		width: 30px;
		height: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 10px;
	}
`;

export const HeaderShareIcons: React.FC = () => {
	const share_data = {
		sc: ShareNet.WhatsApp,
		section: ShareSource.General,
		message: SHARE_MESSAGE_GLOBAL
	};
	const handleShare = (type_share: ShareNet) => {
		share({
			...share_data,
			sc: type_share,
		});
	};

	const whatsApp = shareWhatsApp(share_data);

	return (
		<ShareIcons>
			<button onClick={partial(handleShare, ShareNet.Facebook)}>
				<img src={facebook} alt="facebook"/>
			</button>
			<button onClick={partial(handleShare, ShareNet.Twitter)}>
				<img src={twitter} alt="twitter"/>
			</button>
			<WhatsappShareButton {...whatsApp} resetButtonStyle={false}>
				<img src={whatsapp_icon} alt="whatsapp"/>
			</WhatsappShareButton>
		</ShareIcons>
	)
};