import {createSelector} from "reselect";
import {IRider, IStore} from "modules/types";
import {getSortTableParams} from "modules/selectors/riders_table";
import {getTeamRidersID} from "modules/selectors/team";
import {getSquadsById} from "modules/selectors/squads";
import {keyBy, sortBy, map, memoize, get} from "lodash";
import {RiderStatusType, SortColumnOrder, SortColumnType} from "modules/utils";

export const getPureRiders = ({riders}: IStore) => riders || [];

export const getAllRiders = createSelector(
	getPureRiders,
	getSquadsById,
	(riders = [], squads_by_id) =>
		map(riders, (rider) => ({
			...rider,
			squad: squads_by_id[rider.squad_id],
		}))
);
export const getAllRidersSortedBySeasonPoints = createSelector(getAllRiders, (riders) =>
	sortBy(riders, ({stats}) => -stats.season_points)
);
export const getAllRidersSortedByTotalPoints = createSelector(getAllRiders, (riders) =>
	sortBy(riders, ({stats}) => -stats.total_points)
);
export const getAllRidersSortedByCost = createSelector(getAllRiders, (riders) =>
	sortBy(riders, ({cost}) => -cost)
);

// export const getSortedRiders = createSelector(
// 	isPreseason,
// 	getAllRiders,
// 	(is_preseason, riders) => (
// 		is_preseason ? sortBy(riders, ({ cost }) => -cost) : sortBy(riders, ({ stats }) => -stats.total_points)
// 	)
// );

const status_weight = {
	[RiderStatusType.OnFire]: 4,
	[RiderStatusType.Active]: 3,
	[RiderStatusType.Injured]: 2,
	[RiderStatusType.Disqualified]: 1,
};

export const getRidersWithMarkedInTeam = createSelector(
	getAllRiders,
	getTeamRidersID,
	(riders, riders_in_team) =>
		map(riders, (rider) => ({
			...rider,
			is_in_team: riders_in_team.includes(rider.id),
			status_weight: status_weight[rider.status],
		}))
);

export const getAllRidersById = createSelector(getRidersWithMarkedInTeam, (riders) =>
	keyBy<IRider>(riders, "id")
);

export const getSortedRiders = createSelector(
	getSortTableParams,
	getRidersWithMarkedInTeam,
	(params, riders) =>
		memoize((search: string, score_gp_id: number) => {
			const un_sorted_riders = riders.filter(({first_name, last_name}) =>
				`${first_name} ${last_name}`.toLowerCase().includes(search)
			);
			const key = {
				[SortColumnType.ActualPoints]: `stats.events[${score_gp_id}].points`,
				[SortColumnType.Status]: "status_weight",
				[SortColumnType.Price]: "cost",
				[SortColumnType.Podiums]: "stats.podiums",
			}[params.sort];

			const sorted = sortBy(un_sorted_riders, (rider: IRider) => {
				return get(rider, key, 0);
			});

			return params.order === SortColumnOrder.ASC ? sorted : sorted.reverse();
		})
);
