import {HTTPClient as HTTPClientCore, IRequestConfig} from "@fanhubmedia/fe-common-utils";
import {Storage} from "modules/utils/Storage";
import {Constant} from "modules/utils/Constant";
import {stringify} from "qs";
import axios from "axios";
import {CANCEL} from "redux-saga";
import {get, set, identity, isBoolean, mapValues, each} from "lodash";
import {
	IEmailInvites,
	IFetchLeague,
	ILeagueCreate,
	IPostMyTeamPayload,
	IPostTeamAutoPickPayload,
	IUserBackdoorLoginPayload,
	IUserLoginPayload,
	IPostFetchLeaguesForJoin,
	IPostLeagueJoin,
	IPostLeagueLeave,
	IPostContactForm,
	IUserUpdate,
	ILeagueLadderPayload,
	IMakeTradePayload,
	IShowMyTeamPayload,
	IShowTeamPayload,
	IUserReturnPayload,
	IUserRegisterForGamePayload,
	IApiResponse,
	IUser,
	IMakeTradesPayload,
} from "modules/types";

export const IS_API_ON_THE_SAME_HOST = Constant.API_URL.includes(window.location.host);

class HTTPClient extends HTTPClientCore {
	/**
	 * Overridden method adds CancelToken symbol, that allow redux-saga'
	 * "takeLatest" function to cancel any requests automatically.
	 */
	public makeRequest<T = any>(config: IRequestConfig): Promise<T> {
		const source = axios.CancelToken.source();

		const new_config = {
			...config,
			params: {
				_: Date.now(),
				...config.params,
			},
			cancelToken: source.token,
		};

		if (!IS_API_ON_THE_SAME_HOST) {
			set(new_config, "params.sid", Storage.GET("sid") || "");
		}

		const request = super.makeRequest(new_config);

		// @ts-ignore
		request[CANCEL] = () => source.cancel();

		return request;
	}
}

const getPath = (key: string) => (data: object) => get(data, key);
const onApiResponse = getPath("data");
const onCatchNetworkError = getPath("response.data");

const ApiFormDataClient = new HTTPClient({
	baseURL: Constant.API_URL,
	withCredentials: true,
	transformRequest: [(data) => prepareFormData(data)],
});

const prepareFormData = (data: object) => {
	const prep_data = mapValues(data, (value) => {
		if (isBoolean(value)) {
			return +value;
		}
		return value;
	});
	const formData = new FormData();
	each(prep_data, (value: any, key: string) => {
		if (value instanceof File) {
			formData.append(key, value);
			return;
		}
		formData.append(key, value);
	});
	formData.append("sid", Storage.GET("sid") || "");
	return formData;
};

const APIClient = new HTTPClient({
	baseURL: Constant.API_URL,
	withCredentials: true,
	transformRequest: [stringify],
	onCatchNetworkError,
});

const JSONClient = new HTTPClient({
	baseURL: Constant.JSON_URL,
});

export type TUserResponse = IApiResponse<{user: IUser; session_id: string; is_registered: boolean}>;

export const Api = {
	JSON: {
		checksums: () => JSONClient.get("checksums.json"),
		events: () => JSONClient.get("events.json"),
		riders: () => JSONClient.get("riders.json"),
		constructors: () => JSONClient.get("constructors.json"),
		squads: () => JSONClient.get("squads.json"),
		faq: () => JSONClient.get("faq.json"),
		help_pages: () => JSONClient.get("help_pages.json"),
		countries: () => JSONClient.get("countries.json"),
		widget: () => JSONClient.get("widget.json"),
		prizes: () => JSONClient.get("prizes.json"),
	},
	Auth: {
		login: (params: IUserLoginPayload) => APIClient.post<TUserResponse>("auth/login", params),
		backdoor: (params: IUserBackdoorLoginPayload) =>
			APIClient.post<TUserResponse>("auth/backdoor", params),
		logout: () => APIClient.post("auth/logout"),
	},
	User: {
		create: (data: FormData) =>
			APIClient.request({
				data,
				method: "POST",
				url: "user/create",
				transformRequest: identity,
			})
				.then(onApiResponse)
				.catch(onCatchNetworkError),
		recover: (params: IUserReturnPayload) => APIClient.post("user/recover", params),
		register_for_game: (params: IUserRegisterForGamePayload) =>
			APIClient.post<TUserResponse>("user/register_for_game", params),
		update: (params: IUserUpdate) => ApiFormDataClient.post("user/update", params),
		show_my: () => APIClient.get<TUserResponse>("user/show_my"),
	},
	FantasyTeam: {
		show_my: (params: {event_id: IShowMyTeamPayload}) =>
			APIClient.get("fantasy_team/show_my", params),
		autopick: (params: IPostTeamAutoPickPayload) =>
			APIClient.post("fantasy_team/autopick", params),
		update: (params: IPostMyTeamPayload) => APIClient.post("fantasy_team/update", params),
		show: (params: IShowTeamPayload) => APIClient.get("fantasy_team/show", params),
	},
	FantasyTrade: {
		make_trade: (params: IMakeTradePayload) => APIClient.post("fantasy_trade/make", params),
		make_trades: (params: IMakeTradesPayload) =>
			APIClient.post("fantasy_trade/make_trades", params),
		rollback: () => APIClient.post("fantasy_trade/rollback"),
	},
	League: {
		show: (params: IFetchLeague) => APIClient.get("fantasy_league/show", params),
		show_my: () => APIClient.get("fantasy_league/show_my"),
		create: (params: ILeagueCreate) => APIClient.post("fantasy_league/create", params),
		update: (params: ILeagueCreate) => APIClient.post("fantasy_league/update", params),
		ladder: (params: ILeagueLadderPayload) => APIClient.get("fantasy_league/ladder", params),
	},
	Joins: {
		invite: (params: IEmailInvites) => APIClient.post("fantasy_join/invite", params),
		leave: (params: IPostLeagueLeave) => APIClient.post("fantasy_join/leave", params),
		show: (params: IPostFetchLeaguesForJoin) =>
			APIClient.get("fantasy_join/show_for_join", params),
		join: (params: Partial<IPostLeagueJoin>) => APIClient.post("fantasy_join/join", params),
		is_joined_motul: () => APIClient.get("fantasy_join/is_joined_motul"),
	},
	Contact: {
		save: (params: IPostContactForm) => APIClient.post("contact", params),
	},
};

export * from "./ApiError";

export default Api;
