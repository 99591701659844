import styled from "styled-components";

export const LeaguesTagLineWrapper = styled.div`
	padding: 16px 20px;
	background: #232930;
	box-sizing: border-box;
	display: flex;
	justify-content: space-between;
	margin-top: 20px;

	@media screen and (max-width: 992px) {
		margin-top: 0;
		flex-flow: column;

		> div {
			max-width: 100%;

			&:last-child {
				margin-top: 20px;
			}
		}
	}
`;

interface IButtonsWrapper {
	hide_join?: boolean;
}

export const ButtonsWrapper = styled.div<IButtonsWrapper>`
	display: flex;
	width: 100%;
	justify-content: flex-end;
	gap: 16px;

	a {
		display: flex;
		align-items: center;
		justify-content: center;
		max-width: 160px;
		width: 100%;

		@media screen and (max-width: 992px) {
			max-width: ${({hide_join}) => hide_join && "100%"};
		}
	}
`;

export const Title = styled.div`
	font-size: 14px;
	line-height: 20px;
	color: #fff;
	max-width: 400px;
	width: 100%;
	text-align: left;
`;
