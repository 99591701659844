// Next polyfills are required to made IE11 work.
import "core-js/es";
import "core-js/features/url";
import "core-js/features/url-search-params";

import "assets/css/reset.css";
import "assets/css/fonts.css";
import "assets/css/core.css";

import React, {Suspense} from "react";
import ReactDOM from "react-dom";
import {Preloader} from "components";
import {Constant} from "modules/utils/Constant";
import {init, Integrations} from "@sentry/react";
import {retryFailLoad} from "modules/utils/lazy_load";

const AppComponent = React.lazy(
	retryFailLoad(() => import(`./index_${process.env.REACT_APP_APP_NAME || ""}`))
);

init({
	dsn: "https://c1980966fcdd42268316cdbd64f69f05@o151969.ingest.sentry.io/2489246",
	environment: Constant.ENV,
	sampleRate: 0.1,
	integrations: [
		new Integrations.Breadcrumbs({
			console: false,
		}),
	],
	allowUrls: ["fantasy.motogp.com", ".fanhubmedia.com"],
	denyUrls: [
		"xsca",
		// browser's extensions
		/extensions\//i,
		/^chrome:\/\//i,
		/^moz-extension:\/\//i,
	],
});

ReactDOM.render(
	<Suspense fallback={<Preloader />}>
		<AppComponent />
	</Suspense>,
	document.getElementById("root")
);
