import React, {Fragment} from "react";
import styled from "styled-components";
import chevron_up from "assets/img/chevron-up.svg";
import {Exist} from "components/Exist";

const Title = styled.div`
	font-size: 24px;
	line-height: 32px;
	font-family: var(--fontFamilyAccent);
	color: #fff;
	font-weight: 600;
	text-align: left;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;

	@media screen and (max-width: 992px) {
		font-size: 16px;
		font-weight: 500;
	}
`;

const LeagueFormTitleWrapper = styled.div`
	margin-bottom: 15px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

interface ILeagueFormTitle {
	title?: string;
	title_dashed?: string;
	description?: string | React.ReactElement;
	onToggleView?: () => void;
	is_form_visible?: boolean;
}

const TitleDashed = styled.p`
	font-family: var(--fontFamilyAccent);
	font-size: 20px;
	color: #fff;
	text-transform: uppercase;
`;

interface IToggle {
	up: boolean;
}

const Toggle = styled.div<IToggle>`
	background-image: url("${chevron_up}");
	background-size: 75%;
	background-repeat: no-repeat;
	background-position: center;
	transform: rotate(${({up}) => (up ? "0deg" : "180deg")});
	width: 24px;
	height: 24px;
	cursor: pointer;

	@media screen and (min-width: 992px) {
		display: none;
	}
`;

export const LeagueFormTitle: React.FC<ILeagueFormTitle> = ({
	title,
	title_dashed,
	onToggleView,
	is_form_visible,
}) => (
	<Fragment>
		<LeagueFormTitleWrapper>
			<Exist when={Boolean(title_dashed)}>
				<TitleDashed>{title_dashed}</TitleDashed>
			</Exist>

			<Exist when={Boolean(title)}>
				<Title>
					{title}
					<Toggle up={!!is_form_visible} onClick={onToggleView} />
				</Title>
			</Exist>
		</LeagueFormTitleWrapper>
	</Fragment>
);

export default LeagueFormTitle;
