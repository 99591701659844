import React from "react";
import styled from "styled-components";
import {IRiderStats} from "modules/reducers/riders";

interface ILastPrice {
	is_price_up: boolean;
}

const LastPrice = styled.span<ILastPrice>`
	color: ${({is_price_up}) => (is_price_up ? "#008108" : "#C80502")};
	font-size: 12px;
	font-family: "MotoGPText";
	font-weight: 500;
`;

interface IProps {
	price_diff: IRiderStats["price_diff"];
}

export const PriceChange: React.FC<IProps> = ({price_diff}) => {
	if (!price_diff) {
		return null;
	}

	const is_price_up = price_diff > 0;

	return (
		<LastPrice is_price_up={is_price_up}>
			({is_price_up && "+"}
			{price_diff / 1000}K)
		</LastPrice>
	);
};
