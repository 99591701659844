import styled from 'styled-components';
import whatsapp_icon from 'assets/img/Whatsapp.svg';

export const WhatsAppShareButtonWrapper = styled.div`
	background-color: #25D366;
	color: #FFFFFF;
	width: 280px;
	border-radius: 2px;
	height: 40px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
	font-size: 14px;
	
	button {
		background-image: url(${whatsapp_icon});
		background-size: 25px;
		background-repeat: no-repeat;
		background-position: 30% center;
		font-family: 'MotoGPText';
		display: flex;
		font-size: 14px;
		letter-spacing: 0;
		line-height: 14px;
		font-weight: bold;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		text-transform: uppercase;
	}
`