import React, {Fragment} from "react";
import moment from "moment";
import {isFunction, padStart} from "lodash";
import {Timeout} from "react-number-format/types/types";

const MS_IN_SECOND = 1000;
const SEC_IN_MINUTE = 60;
const MIN_IN_HOUR = 60;
const HRS_IN_DAY = 24;

const defaultTimerValue = {
	days: 0,
	hours: 0,
	minutes: 0,
	seconds: 0,
	diff: 0,
};

interface IProps {
	onComplete?: () => void;
	onTick?: (time_to_end: number) => void;
	children?: (params: IState) => React.ReactNode | React.ReactChildren;
	date: number | string | Date;
}

export interface IState {
	days: number;
	hours: number;
	minutes: number;
	seconds: number;
	diff: number;
	completed: boolean;
}

export class Timer extends React.Component<IProps, IState> {
	public state = {
		...defaultTimerValue,
		completed: false,
	};

	constructor(props: IProps) {
		super(props);

		this.state = {
			...this.state,
			...this.timerValues(this.duration),
		};
	}

	private timer?: number | Timeout;

	/**
	 * @ignore
	 */
	public render() {
		const {children} = this.props;

		if (children) {
			return <Fragment>{children(this.state)}</Fragment>;
		}

		return <Fragment>{this.time}</Fragment>;
	}

	/**
	 * @ignore
	 */
	public componentDidMount() {
		if (this.duration <= 0) {
			return;
		}

		this.timer = setInterval(() => this.diff(), 333);
	}

	/**
	 * @ignore
	 */
	public componentWillUnmount() {
		clearInterval(this.timer);
	}

	private get duration() {
		const startDate = moment();
		const endDate = moment(this.props.date);

		return endDate.diff(startDate);
	}

	private timerValues(diff: number) {
		if (diff < MS_IN_SECOND) {
			return defaultTimerValue;
		}

		const seconds = Math.floor(diff / MS_IN_SECOND);
		const minutes = Math.floor(seconds / SEC_IN_MINUTE);
		const hours = Math.floor(minutes / MIN_IN_HOUR);

		return {
			diff,
			days: Math.floor(hours / HRS_IN_DAY),
			hours: hours % HRS_IN_DAY,
			minutes: minutes % MIN_IN_HOUR,
			seconds: seconds % SEC_IN_MINUTE,
		};
	}

	private diff() {
		const diff = this.duration;
		const {onTick} = this.props;

		if (isFunction(onTick)) {
			onTick(diff);
		}

		if (diff < MS_IN_SECOND) {
			return this.handleCompleteState();
		}

		this.setState(this.timerValues(diff));
	}

	private handleCompleteState() {
		clearInterval(this.timer);

		const {onComplete} = this.props;

		this.setState({
			completed: true,
			...defaultTimerValue,
		});

		if (isFunction(onComplete)) {
			onComplete();
		}
	}

	private get time() {
		const {diff, hours, minutes, seconds} = this.state;

		if (!diff) {
			return "00:00";
		}

		return [hours, minutes, seconds].map((unit) => padStart(String(unit), 2, "0")).join(":");
	}
}

export default Timer;
