import React from "react";
import styled from "styled-components";
import InfoIcon from "assets/img/InfoIcon.svg";
import MuiTooltip, {TooltipProps} from "@mui/material/Tooltip";

export const TooltipBubble = styled.div`
	display: none;
	position: absolute;
	bottom: 100%;
	left: 50%;
	transform: translateX(-50%);
	font-family: var(--fontFamilyPrimary);
	color: #000;
	background: #fff;
	padding: 10px;
	border-radius: 8px;
	width: 154px;
	text-align: center;
	font-size: 12px;
	line-height: 120%;
	margin-bottom: 15px;
	cursor: default;

	&::after {
		content: "";
		position: absolute;
		left: 50%;
		top: 100%;
		transform: translateX(-50%);
		width: 0;
		height: 0;
		border-left: 10px solid rgba(0, 0, 0, 0);
		border-right: 10px solid rgba(0, 0, 0, 0);
		border-top: 10px solid #fff;
	}
`;

const Wrapper = styled.a`
	display: inline-block;
	vertical-align: middle;
	position: relative;
	cursor: pointer;

	&:hover,
	&:focus {
		${TooltipBubble} {
			display: block;
		}
	}
`;

interface IProps {
	text?: string;
}

export const Tooltip: React.FC<IProps> = ({text, ...rest}) => (
	<Wrapper {...rest}>
		{text ? <TooltipBubble>{text}</TooltipBubble> : null}
		<img src={InfoIcon} alt="info" />
	</Wrapper>
);

export const StatusTooltip = styled(({className, ...props}: TooltipProps) => (
	<MuiTooltip {...props} classes={{popper: className}} />
))`
	&.MuiTooltip-popper {
		z-index: 2024;
	}

	.MuiTooltip-tooltip {
		font-family: var(--fontFamilyPrimary);
		font-size: 14px;
		line-height: 120%;
		font-weight: 400;
		padding: 12px;
		padding-top: 8px;
		background-color: #ffffff;
		color: #000000;
	}
	.MuiTooltip-arrow {
		color: #ffffff;
	}
`;
