import styled from "styled-components";

export const LeagueFormDescription = styled.p`
	font-family: "MotoGPText";
	font-size: 16px;
	line-height: 22px;
	color: #fff;
	margin-bottom: 20px;

	span {
		font-weight: 600;
	}
`;

export default LeagueFormDescription;
