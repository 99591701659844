import {Clipboard} from "ts-clipboard";

export const copyToClipboard = (text: string): Promise<object> => {
	return new Promise((resolve, reject) => {
		try {
			Clipboard.copy(text);
			resolve({text});
		} catch (e) {
			console.log(e);
			reject(e);
		}
	});
};
