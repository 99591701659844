import React, {useState} from "react";
import styled, {css} from "styled-components";
import LeagueFormTitle from "./LeagueFormTitle";
import {isUndefined} from "lodash";

const getInactiveFormStyles = ({inactive}: Partial<ILeagueForm>) =>
	inactive &&
	css`
		filter: grayscale(0.5);
		opacity: 0.5;
		pointer-events: none;

		@media screen and (max-width: 992px) {
			display: none;
		}
	`;

interface ILeagueForm {
	title?: string;
	title_dashed?: string;
	inactive?: boolean;
	form?: React.ReactElement;
	width?: string;
	description?: string | React.ReactElement;
	is_commissioner?: boolean;
	changeView?: () => void;
	readonly_form?: boolean;
	onLeagueLeave?: () => void;
	form_visible?: boolean;
}

const getLeagueAboutTitleMobileStyles = ({inactive}: Partial<ILeagueForm>) =>
	isUndefined(inactive) &&
	css`
		padding: 9px 0;

		> div {
			margin-bottom: 0;

			&:last-child {
				margin-top: 20px;
			}
		}

		&.with-top-margin {
			> div {
				&:last-child {
					margin-top: 45px;
				}
			}
		}
	`;

const LeagueFormWrapper = styled.div<Partial<ILeagueForm>>`
	max-width: ${({width}) => (width ? width : "auto")};
	width: 100%;
	box-sizing: border-box;
	border-radius: 2px;
	background-color: #232930;
	padding: 20px;
	transition: all 250ms ease-in-out;
	position: relative;

	@media screen and (max-width: 992px) {
		background-color: transparent;
		box-shadow: none;
		padding: 20px 0;
		margin: 0 auto;

		${getLeagueAboutTitleMobileStyles};
	}
	@media screen and (min-width: 992px) {
		&:first-child {
			margin-right: 30px;
		}
	}

	${getInactiveFormStyles};
`;

interface IFormWrapper {
	is_form_visible: boolean;
}

const FormWrapper = styled.div<IFormWrapper>`
	@media screen and (max-width: 992px) {
		display: ${({is_form_visible}) => (is_form_visible ? "block" : "none")};
	}
`;

export const LeagueForm: React.FC<ILeagueForm> = ({
	width,
	inactive,
	form_visible,
	form,
	...rest
}) => {
	const [is_form_visible, setFormVisibilityState] = useState(!!form_visible);

	const onToggleView = () => {
		setFormVisibilityState(!is_form_visible);
	};

	return (
		<LeagueFormWrapper
			className={!rest.readonly_form ? "with-top-margin" : ""}
			width={width}
			inactive={inactive}>
			<LeagueFormTitle
				{...rest}
				onToggleView={onToggleView}
				is_form_visible={is_form_visible}
			/>

			<FormWrapper is_form_visible={is_form_visible}>{form}</FormWrapper>
		</LeagueFormWrapper>
	);
};

export default LeagueForm;
