import { createSelector } from "reselect";
import {ISquad, IStore} from "modules/types";
import {getTeamSquadsID} from "modules/selectors/team";
import {keyBy, sortBy} from 'lodash';

export const getSquads = ({ squads }: IStore) => squads;

export const getSquadsById = createSelector(
	getSquads,
	squads => keyBy<ISquad>(squads, 'id')
);

export const getAllSquadsSortedByCost  = createSelector(
	getSquads,
	(squads) => sortBy(squads, ({ cost }) => -cost)
);

export const getTeamsWithMarkedInTeam = createSelector(
	getSquads,
	getTeamSquadsID,
	(squads, teams_in_team) => {
		return squads.map(squad => ({
			...squad,
			is_in_team: teams_in_team.includes(squad.id)
		}))
	}
);