import { IShare } from 'modules/types';
import { ShareNet } from 'modules/utils/enums';
import { identity } from 'lodash';

const FB_SHARE_URL = process.env.REACT_APP_FB_SHARE_URL || '';

/**
 * in order to reduce the size of the string for sharing
 */
const last_four_number = -4;
const getTimestamp = () => Date.now().toString().substr(last_four_number);

const getEncodedJson = (object: any) => btoa(JSON.stringify(object));
const getLinkForShare = (object: any, type: string) =>
	`${FB_SHARE_URL}${type}/${getEncodedJson(object)}`;

export const share = (data: IShare) => {
	if (data.sc === ShareNet.Twitter) {
		shareTwitter(data);
	}

	if (data.sc === ShareNet.Facebook) {
		shareFB(data);
	}
	if (data.sc === ShareNet.WhatsApp) {
		shareWhatsApp(data);
	}
};

export const shareFB = (data: IShare) => {
	const { sc, league_id, section, user_id } = data;
	const FB_API = window.FB;
	const object: any = {
		sc,
		t: getTimestamp()
	};

	if (league_id) {
		object.league_id = league_id;
	}

	if (user_id) {
		object.user_id = user_id;
	}

	FB_API.ui({
		method: 'share',
		display: 'popup',
		href: getLinkForShare(object, section),
	}, identity);
};

export const shareTwitter = (data: IShare) => {
	const { sc, league_id, section, user_id, message = '' } = data;
	const object: any = {
		sc,
		t: getTimestamp()
	};

	if (league_id) {
		object.league_id = league_id;
	}

	if (user_id) {
		object.user_id = user_id;
	}

	const href = getLinkForShare(object, section);

	window.open(
		'https://twitter.com/share?url=' + encodeURIComponent(href) +
		'&text=' + encodeURIComponent(message),
		'twitter-share-dialog',
		'width=626,height=436'
	);
};

export const shareWhatsApp = (data: IShare) => {
	const { sc, league_id, section, user_id, message = '' } = data;
	const object: any = {
		sc,
		t: getTimestamp()
	};

	if (league_id) {
		object.league_id = league_id;
	}

	if (user_id) {
		object.user_id = user_id;
	}

	const href = getLinkForShare(object, section);

	return {
		title: message,
		url: href
	};
};