import {createSelector} from "reselect";
import {IStore, ITrade} from "modules/types";
import {chain, identity, filter} from "lodash";
import {TRADES_PER_EVENT} from "modules/utils/Constant";
import {PoolType} from "modules/utils";

export const getMyTeam = ({team}: IStore) => team.team;

export const getTeamConstructorsID = createSelector(getMyTeam, (team) => team.lineup.constructors);

export const getTeamSquadsID = createSelector(getMyTeam, (team) => team.lineup.squads);

export const getTeamRidersID = createSelector(
	getMyTeam,
	({lineup: {riders, riders_reserve}}): number[] => [...riders, ...riders_reserve]
);

export const getPoolType = ({team: {pool_type}}: IStore) => pool_type;

export const getFieldSelectedPosition = ({team: {field_selected_path}}: IStore) =>
	field_selected_path;

export const getTeamSaveStatus = ({team: {is_saved, is_saving}}: IStore) => ({
	is_saved,
	is_saving,
});

export const isTeamSavingOrSaved = createSelector(
	getTeamSaveStatus,
	({is_saved, is_saving}) => is_saved || is_saving
);

export const getSwapPlayer = ({team}: IStore) => team.swap_player;
export const isTeamChanged = ({team}: IStore) => team.team_changed;
export const getTrade = ({team}: IStore) => team.trade_progress;

export const getTrades = ({team}: IStore) => team.trade_progress;
export const getFilledTrades = createSelector(getTrades, (trades) =>
	trades.filter(({id_in, id_out}) => ![id_in, id_out].includes(0))
);

export const getPartialTradesSize = createSelector(getTrades, (trades) => {
	return filter(trades, ({id_in, id_out}) => Boolean(id_in || id_out)).length;
});

export const isTradeInProgress = createSelector(getPartialTradesSize, (in_trade_size) => {
	return in_trade_size !== 0;
});

export const isTradeLocked = createSelector(
	getPartialTradesSize,
	getMyTeam,
	(in_trade_size, team) => {
		return (
			team.event_trades === TRADES_PER_EVENT ||
			in_trade_size === TRADES_PER_EVENT - team.event_trades
		);
	}
);

export const isSaveOrTradeInProgress = createSelector(
	isTeamSavingOrSaved,
	isTradeInProgress,
	(is_save, is_in_trade) => is_save || is_in_trade
);

export const isTeamUpdatedAfterTrade = (state: IStore) => state.team.team_updated_after_trade;

const getIds = (trades: ITrade[], key: keyof ITrade) => {
	return chain(trades)
		.map((trade) => trade[key])
		.filter(identity)
		.value();
};

export const getTradedRiderIds = createSelector(getMyTeam, (team) => {
	const {trades} = team;
	return {
		id_out: getIds(trades, "old_rider_id"),
		id_in: getIds(trades, "new_rider_id"),
	};
});
export const getTradedConstructorIds = createSelector(getMyTeam, (team) => {
	const {trades} = team;
	return {
		id_out: getIds(trades, "old_constructor_id"),
		id_in: getIds(trades, "new_constructor_id"),
	};
});

export const getTradedSquadsIds = createSelector(getMyTeam, (team) => {
	const {trades} = team;
	return {
		id_out: getIds(trades, "old_squad_id"),
		id_in: getIds(trades, "new_squad_id"),
	};
});

export const getRevertTradeModalState = ({team}: IStore) => team.show_revert_modal;

export const getTradedState = createSelector(getMyTeam, ({trades}) => {
	return trades.map((trade) => {
		if (trade.old_rider_id && trade.new_rider_id) {
			return {
				pool_type: PoolType.Riders,
				id_in: trade.new_rider_id,
				id_out: trade.old_rider_id,
			};
		}
		if (trade.old_constructor_id && trade.new_constructor_id) {
			return {
				pool_type: PoolType.Constructors,
				id_in: trade.new_constructor_id,
				id_out: trade.old_constructor_id,
			};
		}
		if (trade.old_squad_id && trade.new_squad_id) {
			return {
				pool_type: PoolType.Squads,
				id_in: trade.new_squad_id,
				id_out: trade.old_squad_id,
			};
		}
		return {
			pool_type: PoolType.Idle,
			id_in: null,
			id_out: null,
		};
	});
});

export const getTimerState = ({team}: IStore) => team.timer_ends;

export const getPoolRiderModalToggle = ({team: {pool_rider_modal}}: IStore) => pool_rider_modal;
export const getUserTeamState = ({team}: IStore) => team.user_team;

export const isShowModalTrade = ({team}: IStore) => team.show_modal_trade;
