import {useSelector} from "react-redux";
import {getLast3EventIDs} from "modules/selectors";
import {get, size} from "lodash";
import {RacePosition} from "components/TeamPool";
import React, {Fragment} from "react";
import {IRiderStatsEvent, ISquadStatsEvent} from "modules/reducers";
import {IDictionary} from "modules/types";

export const Last3Events: React.FC<{
	events: IDictionary<ISquadStatsEvent | IRiderStatsEvent>;
	val?: string;
}> = ({events, val}) => {
	const last3eventIds = useSelector(getLast3EventIDs);

	return (
		<Fragment>
			{size(last3eventIds)
				? last3eventIds.map((id, index) => {
						const event = events[id];
						const highest_position = get(event, val || "highest_position");
						return (
							<RacePosition
								highlight={Boolean(highest_position) && highest_position < 4}
								key={index}>
								{highest_position || "--"}
							</RacePosition>
						);
				  })
				: "--"}
		</Fragment>
	);
};
