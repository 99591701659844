import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getFilledTrades, getTrades, isShowModalTrade} from "modules/selectors";
import styled from "styled-components";
import {IconClose} from "components/Icons/Close";
import {
	cancelTrade,
	hideModalTrade,
	postMakeTrades,
	showCrossPromotionModal,
} from "modules/actions";
import {
	ButtonPrimary,
	ModalOverlay,
	ModalInner,
	CloseModalButton,
	ModalCardRiderReverseTrade,
	ModalCardConstructorOrTeamReverseTrade,
	ButtonSecondary, Exist, TradeRow,
} from "components";
import {find} from "lodash";
import {PoolType} from "modules/utils";
import {ReactComponent as TradeRowIcon} from "assets/img/icons/tradeRowIcon.svg";

const SaveButton = styled(ButtonPrimary)`
	display: block;
	margin: 15px auto 0;
`;
const Title = styled.h2`
	color: #000;
	font-family: var(--fontFamilyAccent);
	font-weight: 600;
	font-size: 26px;
	text-align: center;
	text-transform: uppercase;
	margin-bottom: 20px;
`;

const doNothing = (event: React.SyntheticEvent<HTMLDivElement>) => {
	event.preventDefault();
	event.stopPropagation();
};

const Buttons = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 20px;
	margin-top: 20px;
	button {
		max-width: 200px;
		text-transform: capitalize;
		margin: 0;
	}
	@media screen and (max-width: 460px) {
		flex-direction: column;
	}
`;

// const InnerData: React.FC = () => {
// 	const trades = useSelector(getTrades);
//
// 	return (
// 		<div>
// 			{trades.map(trade => (trade.pool_type === 'rider') ? (
// 				<div>
// 					<SubTitle>Trade In</SubTitle>
// 					<ModalCardRider unit_id={trade.id_in}/>
// 					<SubTitle>Trade Out</SubTitle>
// 					<ModalCardRider unit_id={trade.id_out}/>
// 				</div>
// 			) : (
// 				<div>
// 					<SubTitle>Trade In</SubTitle>
// 					<ModalCardConstructor unit_id={trade.id_in} />
// 					<SubTitle>Trade Out</SubTitle>
// 					<ModalCardConstructor unit_id={trade.id_out} />
// 				</div>
// 			))}
// 		</div>
// 	)
// }

const InnerDataWrapper = styled.div`
	position: relative;
`;

const InnerData = () => {
	const trades = useSelector(getFilledTrades);

	return (
		<InnerDataWrapper>
			{trades.map((trade, index) => {
				console.log(trade);
				if (trade.pool_type === PoolType.Riders) {
					return (
						<TradeRow key={index}>
							<ModalCardRiderReverseTrade unit_id={trade.id_in as number} />
							<Exist when={index === 0}>
								<div className="icon">
									<TradeRowIcon />
								</div>
							</Exist>
							<ModalCardRiderReverseTrade unit_id={trade.id_out as number} />
						</TradeRow>
					);
				}
				return (
					<TradeRow key={index}>
						<ModalCardConstructorOrTeamReverseTrade
							pool_type={trade.pool_type}
							unit_id={trade.id_in as number}
						/>
						<Exist when={index === 0}>
							<div className="icon">
								<TradeRowIcon />
							</div>
						</Exist>
						<ModalCardConstructorOrTeamReverseTrade
							pool_type={trade.pool_type}
							unit_id={trade.id_out as number}
						/>
					</TradeRow>
				);
			})}
		</InnerDataWrapper>
	);
};

export const ModalTrade = () => {
	const dispatch = useDispatch();
	const trades = useSelector(getTrades);
	const show_modal = useSelector(isShowModalTrade);

	const closeModal = useCallback(() => {
		dispatch(cancelTrade());
		dispatch(hideModalTrade());
	}, [dispatch]);

	const makeTrade = useCallback(() => {
		dispatch(postMakeTrades());
		dispatch(showCrossPromotionModal());
		closeModal();
	}, [dispatch, closeModal]);

	const no_trades = find(trades, ({id_in, id_out}) => [id_in, id_out].includes(0));

	if (no_trades && !show_modal) {
		return null;
	}

	return (
		<ModalOverlay onClick={closeModal}>
			<ModalInner onClick={doNothing} width="600px">
				<CloseModalButton onClick={closeModal}>
					<IconClose />
				</CloseModalButton>
				<Title>please revise and confirm the following trades:</Title>
				<InnerData />
				<Buttons>
					<ButtonSecondary className="red" onClick={closeModal}>
						No, Let Me Think About It
					</ButtonSecondary>
					<SaveButton onClick={makeTrade}>Confirm trade</SaveButton>
				</Buttons>
			</ModalInner>
		</ModalOverlay>
	);
};
