import React, {Fragment, useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getTradedState} from "modules/selectors";
import styled from "styled-components";
import {IconClose} from "components/Icons/Close";
import {cancelReverse, postMakeReverse} from "modules/actions";
import {
	ButtonPrimary,
	ModalOverlay,
	ModalInner,
	CloseModalButton,
	ModalCardRiderReverseTrade,
	ModalCardConstructorOrTeamReverseTrade,
	ButtonSecondary, Exist, TradeRow,
} from "components";
import {PoolType} from "modules/utils";
import {ReactComponent as TradeRowIcon} from "assets/img/icons/tradeRowIcon.svg";

const SaveButton = styled(ButtonPrimary)`
	display: block;
	margin: 15px auto 0;
`;
const Title = styled.h2`
	color: #000;
	font-family: var(--fontFamilyAccent);
	font-weight: 600;
	font-size: 26px;
	text-align: center;
	text-transform: uppercase;
	margin-bottom: 20px;
`;

const Buttons = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 20px;
	margin-top: 20px;
	button {
		max-width: 200px;
		text-transform: capitalize;
		margin: 0;
	}
	@media screen and (max-width: 460px) {
		flex-direction: column;
	}
`;

const doNothing = (event: React.SyntheticEvent<HTMLDivElement>) => {
	event.preventDefault();
	event.stopPropagation();
};

const InnerData = () => {
	const trades = useSelector(getTradedState);

	return (
		<div>
			{trades.map((trade, index) => {
				if (trade.pool_type === PoolType.Riders) {
					return (
						<Fragment key={index}>
							<TradeRow>
								<ModalCardRiderReverseTrade unit_id={trade.id_in as number} />
								<Exist when={index === 0}>
									<div className="icon">
										<TradeRowIcon />
									</div>
								</Exist>
								<ModalCardRiderReverseTrade unit_id={trade.id_out as number} />
							</TradeRow>
						</Fragment>
					);
				}
				return (
					<Fragment key={index}>
						<TradeRow>
							<ModalCardConstructorOrTeamReverseTrade
								pool_type={trade.pool_type}
								unit_id={trade.id_in as number}
							/>
							<Exist when={index === 0}>
								<div className="icon">
									<TradeRowIcon />
								</div>
							</Exist>
							<ModalCardConstructorOrTeamReverseTrade
								pool_type={trade.pool_type}
								unit_id={trade.id_out as number}
							/>
						</TradeRow>
					</Fragment>
				);
			})}
		</div>
	);
};

export const ModalRevertTrade = () => {
	const dispatch = useDispatch();
	const trades = useSelector(getTradedState);

	const closeModal = useCallback(() => {
		dispatch(cancelReverse());
	}, [dispatch]);
	const reverseTrade = useCallback(() => {
		dispatch(postMakeReverse());
		closeModal();
	}, [dispatch, closeModal]);

	if (!trades.length) {
		return null;
	}

	return (
		<ModalOverlay onClick={closeModal}>
			<ModalInner onClick={doNothing} width="600px">
				<CloseModalButton onClick={closeModal}>
					<IconClose />
				</CloseModalButton>
				<Title>Are you sure you want to cancel your trade</Title>
				<InnerData />
				<Buttons>
					<ButtonSecondary className="red" onClick={closeModal}>No, Keep These Players</ButtonSecondary>
					<SaveButton onClick={reverseTrade}>Yes, Reverse This Trade{trades.length === 1 ? '' : 's'}</SaveButton>
				</Buttons>

			</ModalInner>
		</ModalOverlay>
	);
};

export default ModalRevertTrade;
