import styled from 'styled-components';
import arrow_up from 'assets/img/triangle-up.svg';
import arrow_down from 'assets/img/triangle-down.svg';

interface IArrow {
	up: boolean;
}

export const Arrow = styled.div<IArrow>`
	width: 16px;
	height: 16px;
	background-image: url("${({ up }) => up ? arrow_up : arrow_down}");
	background-position: center;
	background-repeat: no-repeat;
`;

export default Arrow;
