import styled from "styled-components";
import bg from "assets/img/background.png";

export const Content = styled.main`
	//flex: 1;
	background: url(${bg}) top center no-repeat #000000;
	padding-bottom: 70px;
	@media screen and (max-width: 1000px) {
		padding-bottom: 35px;
	}
`;

export const Container = styled.div`
	width: 100%;
	max-width: 1240px;
	padding: 0 20px;
	box-sizing: border-box;
	margin: 0 auto;
`;

export const InnerContainer = styled(Container)`
	max-width: 1200px;
	padding: 25px 0;
	@media screen and (max-width: 1000px) {
		padding: 20px;
	}
`;

export const InnerContainerSM = styled(Container)`
	max-width: 1200px;
`;

export * from "./SubHeader";
