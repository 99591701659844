import {Constant} from "modules/utils/Constant";
import {onImageErrorHideIt, PoolType} from "modules/utils";
import React from "react";
import {ModalCardConstructorName, ModalCardFigure, ModalCardPlayerDescriptionBlock, ModalCardWrapper} from "components";
import {useSelector} from "react-redux";
import {getAllConstructorsById} from "modules/selectors/constructors";
import {getSquadsById} from "modules/selectors";

export const ModalCardConstructorOrTeamReverseTrade: React.FC<{
	unit_id: number;
	pool_type: PoolType;
}> = ({unit_id, pool_type}) => {
	const isConstructor = pool_type === PoolType.Constructors;
	const constructors_by_id = useSelector(getAllConstructorsById);
	const squads_by_id = useSelector(getSquadsById);
	const unit = isConstructor ? constructors_by_id[unit_id] : squads_by_id[unit_id];
	const url = isConstructor ? Constant.CONSTRUCTORS_URL : Constant.TEAMS_URL;
	return (
		<ModalCardWrapper className="column">
			<ModalCardFigure>
				<img
					src={`${url + unit_id}_3.png`}
					onError={onImageErrorHideIt}
					alt={unit.name}
					title={unit.name}
				/>
			</ModalCardFigure>
			<ModalCardPlayerDescriptionBlock>
				<ModalCardConstructorName>{unit.name}</ModalCardConstructorName>
			</ModalCardPlayerDescriptionBlock>

		</ModalCardWrapper>
	);
};
