import { createAction } from "redux-act";
import { IRider, ITeam, ITeamLineup, IUserTeam } from "modules/types";
import {PoolType} from "modules/utils";

export type IShowMyTeamPayload = string | undefined;
export interface IShowTeamPayload {
	team_id: string;
	league_id: number;
	event_id: number;
}

export const fetchMyTeam = createAction<IShowMyTeamPayload>();
export const fetchMyTeamSuccess = createAction<ITeam>();
export const fetchMyTeamFailed = createAction<Error>();

export interface IPostTeamAutoPickPayload {
	lineup: ITeamLineup;
}

export const postTeamAutoPick = createAction();
export const postTeamAutoPickSuccess = createAction<ITeam>();
export const postTeamAutoPickFailed = createAction<Error>();

export interface IPostMyTeamPayload extends IPostTeamAutoPickPayload {
	turbo_rider?: number;
}

export const postMyTeam = createAction();
export const postMyTeamSuccess = createAction<ITeam>();
export const postMyTeamFailed = createAction<Error>();

export const clearMyTeam = createAction();
export const removeFromTeam = createAction<number>();
export const switchPoolType = createAction<PoolType>();

export interface IFieldPositionPayload {
	field_selected_path: string;
	pool_type: PoolType;
}

export const setFieldSelectedPosition = createAction<IFieldPositionPayload>();
export const clearFieldPositionSelection = createAction();
export const updateLineup = createAction<ITeamLineup>();

export interface ISetUnitPositionPayload {
	id: number;
	type: PoolType;
}

export const setUnitToField = createAction<ISetUnitPositionPayload>();

export const selectTurbo = createAction<number>();
export const swapRider = createAction<number>();

export interface ITradePayload {
	pool_type: PoolType,
	id: number
}

export interface IMakeTradePayload {
	team_id: number;
	old_rider_id?: number;
	new_rider_id?: number;
	old_constructor_id?: number;
	new_constructor_id?: number;
}

export interface ITradesPayload {
	old_rider_id?: number;
	new_rider_id?: number;
	old_constructor_id?: number;
	new_constructor_id?: number;
}
export interface IMakeTradesPayload {
	team_id: number;
	trades: ITradesPayload[]
}

export const setTradeIdOut = createAction<ITradePayload>();
export const setTradeIdIn = createAction<ITradePayload>();
export const postMakeTrade = createAction();
export const postMakeTradeSuccess = createAction();
export const postMakeTradeFailed = createAction();
export const postMakeTrades = createAction();
export const postMakeTradesSuccess = createAction();
export const postMakeTradesFailed = createAction();
export const clearTrade = createAction();
export const cancelTrade = createAction();
export const showRevertModal = createAction();
export const cancelReverse = createAction();
export const postMakeReverse = createAction();
export const setTimerState = createAction<boolean>();
export const showOverBudgetError = createAction();

export interface IPoolRiderModalState {
	modal_rider?: IRider;
	score?: number;
	event_id: number;
	show_modal: boolean;
}

export const poolRiderModalToggle = createAction<IPoolRiderModalState>();
export const getUserTeam = createAction<IShowTeamPayload>();
export const getUserTeamSuccess = createAction<IUserTeam>();
export const getUserTeamFailed = createAction<Error>();
export const clearUserTeam = createAction();

export const showModalTrade = createAction();
export const hideModalTrade = createAction();