import ButtonPrimary from "./ButtonPrimary";
import styled from "styled-components";

export const ButtonSecondary = styled(ButtonPrimary)`
	--color: #fff;
	--hoverColor: #000;
	--backgroundColor: transparent;
	--hoverBorderColor: #fff;
	--hoverBackgroundColor: #fff;

	border: 1px solid var(--color);
	color: var(--color);
	background-color: var(--backgroundColor);
	box-sizing: border-box;

	&:hover:not(:disabled) {
		background: var(--hoverBackgroundColor);
		color: var(--hoverColor);
		border-color: var(--hoverBorderColor);
	}
	&:disabled {
		border-color: #959595;
		color: #959595;
		background-color: transparent;
		opacity: 0.3;
	}
	&.red {
		--color: #e41210;
		--hoverColor: #fff;
		--hoverBorderColor: #a50301;
		--hoverBackgroundColor: #a50301;

		&.active {
			--color: #fff;
			--backgroundColor: #e41210;
			--hoverBorderColor: #a50301;
			--hoverBackgroundColor: #a50301;
		}
	}
`;
