import React, {Fragment} from "react";
import {inviteViaEmail} from "../../modules/actions/leagues";
import {IEmailInvite, IEmailInvites, ILeague} from "../../modules/reducers/leagues";
import {eq, get} from "lodash";
import LeagueForm from "./LeagueForm";
import {LeagueFormInvite} from "./LeagueFormInvite";
import {useDispatch} from "react-redux";

interface IProps {
	inactive?: boolean;
	league?: ILeague;
	update?: boolean;
}

export const LeagueInvite: React.FC<IProps> = ({league, inactive, update}) => {
	const dispatch = useDispatch();

	let league_invite_form_data: IEmailInvites = {
		league_id: 0,
		invites: [],
	};

	const invite_league_title = "Step 2: Invite Your Friends";

	const invite_league_description = () => (
		<Fragment>
			Great, now that you have created a league, invite others to join.
			<br />
			Your unique League code for {league?.name && league.name} is:{" "}
			{league?.code && <span>{league.code}</span>}
		</Fragment>
	);

	const handleInviteFormSubmit = (event: React.SyntheticEvent<HTMLFormElement>) => {
		event.preventDefault();
		league_invite_form_data.league_id = league?.id || 0;

		dispatch(inviteViaEmail(league_invite_form_data));
	};

	const handleInviteFormChange = (fields: IEmailInvite[]) => {
		league_invite_form_data.invites = [...fields];
	};

	const onInvalidInviteForm = (event: any) => {
		const value = get(event, "target.value", "");
		const validation_message = eq(value, "")
			? "Please provide your friend's name and email address"
			: "";

		event.target.setCustomValidity(validation_message);
	};

	if (update && league) {
		return (
			<LeagueForm
				title={"Invite Your Friends"}
				form={
					<LeagueFormInvite
						update={update}
						league={league}
						onSubmit={handleInviteFormSubmit}
						onChange={handleInviteFormChange}
						onInvalid={onInvalidInviteForm}
						description={invite_league_description()}
						inactive={inactive}
					/>
				}
			/>
		);
	}

	return (
		<LeagueForm
			inactive={inactive}
			title_dashed={invite_league_title}
			width="100%"
			form_visible={true}
			form={
				<LeagueFormInvite
					inactive={inactive}
					league={league}
					onSubmit={handleInviteFormSubmit}
					onChange={handleInviteFormChange}
					onInvalid={onInvalidInviteForm}
					description={invite_league_description()}
				/>
			}
		/>
	);
};

export default LeagueInvite;
