import React from 'react';
import styled from 'styled-components';
import { Direction } from 'modules/types';
import { cond, matches, constant } from 'lodash';

interface ISvgProps {
	to?: Direction;
}

const getDirectionValue = cond([
	[matches({ to: 'top' }), constant(0)],
	[matches({ to: 'right' }), constant(-90)],
	[matches({ to: 'bottom' }), constant(-180)],
	[matches({ to: 'left' }), constant(-270)],
]);

const Svg = styled.svg<ISvgProps>`
	transform: rotate(${getDirectionValue}deg);
`;

interface IProps extends ISvgProps {
	width?: number;
	height?: number;
	color?: string;
}

export const ArrowSolid: React.FC<IProps> = ({ width, height, color, ...rest }) => (
	<Svg width={`${width}px`} height={`${height}px`} viewBox="0 0 10 5" {...rest}>
		<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<g transform="translate(-15.000000, -10.000000)" fill={color}>
				<polygon points="15 15 20 10 25 15"/>
			</g>
		</g>
	</Svg>
);

ArrowSolid.defaultProps = {
	width: 10,
	height: 5,
	color: '#B8B8B8',
	to: 'top',
};